import React, { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import UsersAsync from 'store/users/usersAsync';
// Models
import IUser from 'models/User';
// Types
import UserRoles from 'types/UserRoles';
// Components
import Phone from 'components/Phone';
import Title from 'components/Title';
import { Input } from 'components/Controls';
// MUI
import { LoadingButton } from '@mui/lab';
import {
  Button, DialogActions, DialogContent, DialogTitle,
  Grid, MenuItem, TextField
} from '@mui/material';
// utilites
import { isEmail, isRequired } from 'utilities/Validation';

interface Props {
  onClose: () => void;
  user?: IUser;
  roles?: UserRoles[];
}

interface IForm {
  role: UserRoles;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  password?: string;
}

const UsersForm: React.FC<Props> = ({ onClose, user, roles = [] }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, formState: {errors} } = useForm<IForm>({
    defaultValues: {
      role: user ? user.role : roles.includes(UserRoles.Client) ? UserRoles.Client : UserRoles.Manager,
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phone || '',
      password: '',
    }
  });

  const onSubmit = handleSubmit((data: IForm) => {
    const { password, ...nextData } = data;
    
    setIsLoading(true);

    if (user) {
      dispatch(UsersAsync.updateUser({userId: user._id, userData: nextData}))
      .unwrap()
      .then(() => onClose())
      .finally(() => setIsLoading(false))
    } else {
      dispatch(UsersAsync.createUser(data))
      .unwrap()
      .then(() => onClose())
      .finally(() => setIsLoading(false))
    }
  });

  return (
    <Fragment>
      <DialogTitle>
        <Title>{`${user ? 'Редагувати' : 'Створити'} користовача`}</Title>
      </DialogTitle>

      <DialogContent dividers>
        <form noValidate>
          <Grid container spacing={2} sx={{ pt: 4, pb: 4 }}>
            {!roles.includes(UserRoles.Client) && user?.role !== UserRoles.Client && (
              <Grid item xs={12}>
                <Controller
                  control={control} name="role"
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      label="Роль"
                      fullWidth
                      required
                      error={!!errors?.role}
                      helperText={errors?.role ? errors.role.message : null}
                    >
                      <MenuItem value={UserRoles.Admin}>Адміністратор</MenuItem>
                      <MenuItem value={UserRoles.Manager}>Менеджер</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
            )}
            {/* email */}
            <Grid item xs={12}>
              <Controller
                control={control} name="email"
                rules={{ required: isRequired, pattern: isEmail }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="E-mail"
                    autoComplete="off"
                    fullWidth
                    required
                    error={!!errors?.email}
                    helperText={errors?.email ? errors.email.message : null}
                  />
                )}
              />
            </Grid>
            {/* firstName */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="firstName"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Ім'я"
                    fullWidth
                    required
                    error={!!errors?.firstName}
                    helperText={errors?.firstName ? errors.firstName.message : null}
                  />
                )}
              />
            </Grid>
            {/* lastName */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="lastName"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Прізвище"
                    fullWidth
                    required
                    error={!!errors?.lastName}
                    helperText={errors?.lastName ? errors.lastName.message : null}
                  />
                )}
              />
            </Grid>
            {/* phone */}
            <Grid item xs={12}>
              <Controller
                control={control} name="phone"
                rules={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                  <Phone
                    value={value || ''}
                    onChange={onChange}
                    label="Телефон"
                    required
                    error={!!errors?.phone}
                    helperText={errors?.phone ? errors.phone.message : null}
                  />
                )}
              />
            </Grid>
            {!user && (
              <Grid item xs={12}>
                <Controller
                  control={control} name="password"
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      fullWidth
                      label="Пароль"
                      type="password"
                      autoComplete="off"
                      required
                      error={!!errors?.password}
                      helperText={errors?.password ? errors.password.message : null}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </form>        
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Скасувати
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
        >
          {user ? 'Зберегти' : 'Створити'}
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
};

export default UsersForm;
