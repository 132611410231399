import React, { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import PricesAsync from 'store/prices/pricesAsync';
// Models
import IPrice from 'models/Price';
// Components
import Title from 'components/Title';
import Uploader from 'components/Uploader';
// mui
import { LoadingButton } from '@mui/lab';
import {
  Button, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText,
  Grid, InputAdornment, InputLabel, OutlinedInput, Switch, TextField
} from '@mui/material';
// utilities
import { isRequired } from 'utilities/Validation';

type Props = {
  onClose: () => void,
  price?: IPrice,
}

interface IForm {
  title: string;
  price: number | string;
  description: string;
  enabled: boolean;
}

const PricesForm:React.FC<Props> = ({ price, onClose }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm<IForm>({
    defaultValues: {
      title: price?.title || '',
      price: price?.price || '',
      description: price?.description || '',
      enabled: price?.enabled || false,
    }
  })

  const onSubmit = handleSubmit((data: IForm) => {
    setIsLoading(true);

    if (price) {
      dispatch(PricesAsync.updatePrice({ price: data, priceId: price._id }))
        .unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false))
    } else {
      dispatch(PricesAsync.createPrice(data))
        .unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false))
    }
  })

  return (
    <Fragment>
      <DialogTitle>
        <Title>{`${price ? 'Редагувати' : 'Створити'} ціну`}</Title>
      </DialogTitle>

      <DialogContent dividers>
        <form noValidate>
          <Grid container spacing={2} sx={{ pt: 4, pb: 4 }}>
            {/* enabled  */}
            <Grid item xs={12}>
              <Controller
                control={control} name="enabled"
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    label="Показувати на  сайті"
                    control={<Switch checked={value} onChange={onChange} />}
                  />
                )}
              />
            </Grid>
            {/* title */}
            <Grid item xs={12}>
              <Controller
                control={control} name="title"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Заголовок"
                    fullWidth
                    required
                    error={!!errors?.title}
                    helperText={errors?.title ? errors.title.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {/* price */}
              <Controller
                control={control} name="price"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal" required>
                    <InputLabel>Price</InputLabel>
                    <OutlinedInput
                      {...field}
                      startAdornment={<InputAdornment position="start">₴</InputAdornment>}
                      label="Ціна"
                      type="number"
                      required
                      error={Boolean(errors.price)}
                    />
                    <FormHelperText sx={{ color: '#d32f2f' }}>{errors.price?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            {/* description */}
            <Grid item xs={12}>
              <Controller
                control={control} name="description"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Опис"
                    fullWidth
                    multiline
                    rows={5}
                  />
                )}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Uploader assets={price?.image ? [price.image] : undefined} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Скасувати
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
        >
          {price ? 'Зберегти' : 'Створити'}
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
}

export default PricesForm;
