import React from 'react';
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
// Models
import IUser from 'models/User';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';
import { selectCurrentUser } from 'store/users/usersSelectors';

type Props = {
  children: JSX.Element;
}

const PublicRoute:React.FC<Props> = ({ children }) => {
  const isAuthenticated:boolean | null = useSelector(selectIsAuthenticated);
  const currentUser:IUser | null = useSelector(selectCurrentUser);

  if ( isAuthenticated && currentUser ) return <Navigate to={`/${currentUser.role}`} replace />;

  return children;
};

export default PublicRoute;
