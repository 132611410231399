import React from 'react';
// Models
import IUser from 'models/User';
// MUI
import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
// Utilities
import { formatPhone } from 'utilities/StringFromatter';
import { makeStyles } from '@mui/styles';

type Props = {
  user: IUser,
  onClose: () => void,
};

const UserInfo:React.FC<Props> = ({ user, onClose }) => {
  const classes = useStyles();

  const imgUrl = user.image ? user.image.path : '/images/person.png';

  return (
    <React.Fragment>
      <DialogContent dividers sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        <Box sx={{
          border: '2px solid #eee',
          borderRadius: '50%',
          overflow: 'hidden',
          width: { xs: '75px', md: '120px' },
          height: { xs: '75px', md: '120px' },
          marginLeft: 'auto',
          marginRight: 'auto',
          my: { xs: 1, md: 2 },
        }}>
          <img
            src={imgUrl} alt=""
            style={{
              width: '100%', height: '100%',
              objectFit: 'cover', objectPosition: 'center',
            }}
          />
        </Box>
        
        <Typography sx={{ textAlign: 'center', fontSize: { xs: '24px', md: '32px' }, mb: 3 }}>{`${user.firstName} ${user.lastName}`}</Typography>

        <Box sx={{ mb: 1 }}>
          <Typography className={classes.title}>E-mail</Typography>
          <Typography className={classes.subtitle}>{user.email}</Typography>
        </Box>
        <Box sx={{ mb: 1 }}>
          <Typography className={classes.title}>Телефон</Typography>
          <Typography className={classes.subtitle}>
            {user.phone ? formatPhone(user.phone) : ''}
          </Typography>
        </Box>
        {user.team && (
          <Box sx={{ mb: 1 }}>
            <Typography className={classes.title}>Команда</Typography>
            <Typography className={classes.subtitle}>{user.team}</Typography>
          </Box>
        )}
        {user.carNumbers && !!user.carNumbers.length && (
          <Box sx={{ mb: 1 }}>
            <Typography className={classes.title}>{`Номер(а) авто`}</Typography>
            <Typography className={classes.subtitle}>
              {user.carNumbers.join(', ')}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Закрити
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default UserInfo;

const useStyles = makeStyles({
  title: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  subtitle: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
})