import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
// hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import UsersAsync from 'store/users/usersAsync';
// Models
import IUser from 'models/User';
//Types
import UserRoles from 'types/UserRoles';
// selectors
import { selectUsers, selectTotal, selectParams } from 'store/users/usersSelectors';
// components
import UserItem from "./UserItem";
import NoData from 'components/NoData';
// mui
import { Box, LinearProgress, TablePagination } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { usersActions } from 'store/users/usersSlice';

const UsersList:React.FC<{ roles: UserRoles[] }> = ({ roles }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const users = useSelector(selectUsers);
  const total = useSelector(selectTotal);
  const params = useSelector(selectParams);

  const handleChangePage = (_:any, page: number) => {
    dispatch(UsersAsync.fetchUsers({ ...params, roles, page: page + 1 }));
  }

  const handleChangeLimit = (e:any) => {
    const { value: limit } = e.target;
    dispatch(UsersAsync.fetchUsers({ ...params, roles, page: 1, limit, }));
  }

  useEffect(() => {
    dispatch(UsersAsync.fetchUsers({...params, roles}));

    return () => {
      dispatch(usersActions.setInitialField('params'));
    }
    // eslint-disable-next-line
  }, []);

  if (!users) return <LinearProgress sx={{ mt: 2 }} />;
  if (users && !users.length) return <NoData />;
  return (
    <Box className={classes.list} sx={{ mt: 3 }}>
      <Box className={classes.items}>
        {users?.map((user: IUser) => (
          <UserItem key={user._id} user={user} />
        ))}
      </Box>
      <Box>
        <TablePagination
          className={classes.pagination}
          component="div"
          count={total}
          page={params.page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={params.limit}
          onRowsPerPageChange={handleChangeLimit}
          labelRowsPerPage="Показувати"
          labelDisplayedRows={({from, to, count}) => `${from}–${to} з ${count}`}
          rowsPerPageOptions={[20, 50, 100]}
        />
      </Box>
    </Box>
  );
}

export default UsersList;

const useStyles = makeStyles({
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 208px)',
    },
  },
  pagination: {
    boxShadow: '0px -3px 6px -1px rgb(0 0 0 / 8%)',
  },
});
