import { combineReducers, configureStore } from "@reduxjs/toolkit";
// Store
import authReducer from 'store/auth/authSlice';
import appReducer from 'store/app/appSlice';
import randomReducer from 'store/random/randomSlice';
import usersReducer from 'store/users/usersSlice';
import reservationsReducer from 'store/reservationsRequests/reservationsRequestsSlice';
import assetsRedcuer from 'store/assets/assetsSlice';
import sponsorsRedcuer from 'store/sponsors/sponsorsSlice';
import competitionsRedcuer from 'store/competitions/competitionsSlice';
import pricesRedcuer from 'store/prices/pricesSlice';
import templatesRedcuer from 'store/templates/templatesSlice';
// middlewares
import errorMiddleware from "middlewares/errorMiddelware";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  random: randomReducer,
  users: usersReducer,
  reservations: reservationsReducer,
  assets: assetsRedcuer,
  sponsors: sponsorsRedcuer,
  competitions: competitionsRedcuer,
  prices: pricesRedcuer,
  templates: templatesRedcuer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(errorMiddleware)
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch']; 
