import React from 'react';
import RandomHeader from './RandomHeader';
import RandomList from './RandomList';

const RandomPage:React.FC = () => {
  return (
    <React.Fragment>
      <RandomHeader />
      <RandomList />
    </React.Fragment>
  );
}

export default RandomPage;
