import React from 'react';
// Hooks
import useDialog from 'hooks/useDialog';
// Types
import UserRoles from 'types/UserRoles';
// Mui
import { Button, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// Components
import Title from 'components/Title';
import UsersForm from './UsersForm';

const UsersHeader:React.FC<{roles: UserRoles[]}> = ({ roles }) => {
  const { Dialog, openDialog, closeDialog } = useDialog();

  const userRole = roles.includes(UserRoles.Client) ? 'Клієнт' : 'Адміністратор';

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title>{`${userRole}и`}</Title>
        <Button
          sx={{ display: { xs: 'none', md: 'inline-flex' } }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={openDialog}
        >
          {`Додати ${userRole}а`}
        </Button>
        <IconButton
          sx={{ display: { xs: 'inline-flex', md: 'none' } }}
          color="primary"
          onClick={openDialog}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Dialog maxWidth="sm">
        <UsersForm onClose={closeDialog} roles={roles} />
      </Dialog>
    </React.Fragment>
  );
};

export default UsersHeader;
