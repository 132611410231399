import React, { useState } from 'react';
// hooks
import useDialog from 'hooks/useDialog';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import CompetitionsAsync from 'store/competitions/competitionsAsync';
// Models
import ICompetition from 'models/Competition';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Box, Card, Grid, IconButton, Tooltip, Menu, MenuItem
} from '@mui/material';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Components
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import CompetitionsForm from './CompetitionsForm';
// Utilities
import { formatStartEndDate } from 'utilities/DateTimeFormatter';

type Props = {
  competition: ICompetition,
};

const CompetitionItem:React.FC<Props> = ({ competition }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // handle remove modal
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const handleOpenRemoveModal = () => {
    setOpenRemoveModal(true);
    handleClose();
  }

  const handleCloseRemoveModal = () => {
    setOpenRemoveModal(false);
  }

  const handleRemoveCompetition = () => {
    dispatch(CompetitionsAsync.deleteCompetition(competition._id));
  }

  // handle Open Menu

  const [openMenu, setOpenMenu] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  // Edit Dialog

  const { Dialog, openDialog, closeDialog } = useDialog();

  const openEditDialog = () => {
    openDialog();
    handleClose();
  }

  return (
    <React.Fragment>
      <ConfirmDeleteModal open={openRemoveModal} onClose={handleCloseRemoveModal} handleRemove={handleRemoveCompetition} title="змагання" />
      <Dialog maxWidth="sm">
        <CompetitionsForm onClose={closeDialog} competition={competition} />
      </Dialog>
      <Card sx={{ p: 2, borderRadius: 0, backgroundColor: '#fff', borderBottom: '1px solid #eee', width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} md={6} alignSelf="center" sx={{ order: -1 }}>
            <Typography className={classes.title}>Назва:</Typography>
            <Typography className={classes.text}>
              {competition.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} alignSelf="center">
            <Typography className={classes.title}>Дата проведення змагань:</Typography>
            <Typography className={classes.text}>
              {formatStartEndDate(competition.startDate, competition.endDate)}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} alignSelf="center" sx={{ order: { xs: -1, md: 0 } }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Open context menu">
                <IconButton aria-label="open context menu" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="propertyOwnership-context-menu"
                anchorEl={openMenu}
                open={Boolean(openMenu)}
                onClose={handleClose}
              >
                <MenuItem onClick={openEditDialog}>Редагувати</MenuItem>
                <MenuItem onClick={handleOpenRemoveModal} sx={{ color: '#D32F2F' }}>Видалити</MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}

export default CompetitionItem;

const useStyles = makeStyles({
  title: {
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
