import { createAsyncThunk } from "@reduxjs/toolkit";
// Models
import ITemplate from "models/Template";
// Actions
import { appActions } from "store/app/appSlice";
// Utilities
import HttpClient from "utilities/HttpClient";

const url = '/templates';
const http:HttpClient = new HttpClient();

const TemplatesAsync = {
  fetchTemplates: createAsyncThunk('templates/fetchTemplates', async () => {
    const { parseBody } = await http.get(url);
    return parseBody as ITemplate[];
  }),
  updateTemplate: createAsyncThunk('templates/updateTemplate', async (data:any, thunkApi) => {
    const { templateId, templateData } = data;
    const { parseBody } = await http.put(`${url}/${templateId}`, templateData);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Шаблон успішно оновлено' }));
    return parseBody as ITemplate;
  }),
}

export default TemplatesAsync;
