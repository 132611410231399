import React from 'react';
// components
import TemplatesHeader from './TemplatesHeader';
import TemplatesList from './TemplatesList';

const TemplatesPage:React.FC = () => {
  return (
    <React.Fragment>
      <TemplatesHeader />
      <TemplatesList />
    </React.Fragment>
  )
}

export default TemplatesPage;
