import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  sectors: number[];
  participants: string[];
  status: boolean;
}

const initialState: IState = {
  sectors: Array.from({length: 21}, (_, i) => i),
  participants: [],
  status: false,
}

const randomSlice = createSlice({
  name: 'random',
  initialState,
  reducers: {
    setSectors: (state, action) => {
      state.sectors = action.payload;
    },
    addParticipants: (state, action) => {
      state.participants = [...state.participants, action.payload];
    },
    removeParticipants: (state, action) => {
      state.participants = state.participants.filter(participant => participant !== action.payload);
    },
    random: (state) => {
      const sectors = [...state.sectors];

      state.participants = state.participants.map(participant => {
        const random = Math.floor(Math.random() * sectors.length);
        const sector = sectors.splice(random, 1);
        return (
          `${participant}: Сектор № ${sector}`
        )
      });
      state.status = true;
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  }
})

export const randomActions = randomSlice.actions;
export default randomSlice.reducer;
