import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Types
import UserRoles from 'types/UserRoles';
// Pages
import ProfilePage from './ProfilePage';
import RandomPage from './Random';
import ReservationsRequests from './ReservationsRequests';
import SponsorsPage from './Sponsors';
import TemplatesPage from './Templates';
import UsersPage from './Users';
import CompetitionsPage from './Competitions';
import PricesPage from './Prices';

const AdminRouting:React.FC = () => {
  return (
    <Routes>
      <Route path="admins" element={<AdminsWrapper />} />
      <Route path="clients" element={<ClientsWrapper />} />
      <Route path="reservationsRequests" element={<ReservationsRequests />} />
      <Route path="competitions" element={<CompetitionsPage />} />
      <Route path="sponsors" element={<SponsorsPage />} />
      <Route path="prices" element={<PricesPage />} />
      <Route path="templates" element={<TemplatesPage />} />
      <Route path="random" element={<RandomPage />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="*" element={<Navigate to="/admin/reservationsRequests" />} />
    </Routes>
  )
}

export default AdminRouting;

const AdminsWrapper = () => (
  <UsersPage roles={[UserRoles.Admin, UserRoles.Manager]} />
);

const ClientsWrapper = () => (
  <UsersPage roles={[UserRoles.Client]} />
);
