import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
dayjs.extend(weekOfYear);
dayjs.locale('uk');

export const formatStartEndDate = (startDate:string, endDate:string):string => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const isSameYear = start.isSame(end, 'year');
  const isSameMonth = start.isSame(end, 'month');
  const isSameDay = start.isSame(end, 'day');
  
  return isSameDay ?
    dayjs(start).format('DD MMMM YYYY')
      : isSameMonth
        ? `${dayjs(start).format('DD')} - ${dayjs(end).format('DD MMM, YYYY')}`
        : isSameYear
          ? `${dayjs(start).format('DD MMM')} - ${dayjs(end).format('DD MMM, YYYY')}`
          : `${dayjs(start).format('DD MMM, YY')} - ${dayjs(end).format('DD MMM, YY')}`
}
