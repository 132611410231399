import { FC, Fragment } from 'react';
// Hooks
import useDialog from 'hooks/useDialog';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useToggle from 'hooks/useToggle';
import useMenu from 'hooks/useMenu';
// Async
import ReservationsRequestsAsync from 'store/reservationsRequests/reservationsRequestsAsync';
// Models
import IReservationRequest from 'models/ReservationRequest';
// Types
import ReservationRequestStatuses from 'types/ReservationRequestStatuses';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Box, Card, Chip, Collapse, Divider, Grid,
  IconButton, Tooltip, Typography
} from '@mui/material';
import {
  DoneAll, Close, AccessTimeOutlined,
  ReportOutlined, MoreVert
} from '@mui/icons-material';
// Components
import Collapsable from 'components/Collapsable';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ReservationsForm from './ReservationsRequestsForm';
import UserInfo from 'components/UserInfo';
// Utilities
import { formatStartEndDate } from 'utilities/DateTimeFormatter';
import { formatPhone } from 'utilities/StringFromatter';

type Props = {
  reservationsRequest: IReservationRequest,
};

const ReservationRequestItem:FC<Props> = ({ reservationsRequest }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleRemoveReservationRequest = () => {
    dispatch(ReservationsRequestsAsync.deleteReservationRequest(reservationsRequest._id));
  }

  const markAsSubmitted = () => {
    dispatch(ReservationsRequestsAsync.markAsSubmitted(reservationsRequest._id));
  }

  const handleRejectReservation = () => {
    dispatch(ReservationsRequestsAsync.reject(reservationsRequest._id));
  }

  const { Menu, MenuItem, openMenu } = useMenu();

  const { open:openRemoveModal, toggle:toggleRemoveModal } = useToggle();
  const { open:extended, toggle:toggleExtended } = useToggle();

  const { Dialog, openDialog, closeDialog } = useDialog();
  const { Dialog:UserInfoDialog, openDialog:openUserInfoDialog, closeDialog:closeUserInfoDialog } = useDialog();

  return (
    <Fragment>
      <ConfirmDeleteModal open={openRemoveModal} onClose={toggleRemoveModal} handleRemove={handleRemoveReservationRequest} title="запит на бронювання" />

      <Dialog>
        <ReservationsForm onClose={closeDialog} reservationRequest={reservationsRequest} />
      </Dialog>

      {reservationsRequest.user ? (
        <UserInfoDialog>
          <UserInfo user={reservationsRequest.user} onClose={closeUserInfoDialog} />
        </UserInfoDialog>
      ) : null}

      <Card sx={{ p: 2, borderRadius: 0, backgroundColor: extended ? 'rgba(0, 0, 0, 0.04)' : '#fff', borderBottom: '1px solid #eee', width: '100%' }}>
        <Collapsable onClick={toggleExtended}>
          <Grid container spacing={2}>
            <Grid item xs={10} md={3} alignSelf="center" sx={{ order: -1 }}>
              <Typography className={classes.title}>Ім'я:</Typography>
              <Typography
                className={classes.text}
                onClick={() => {
                  if (reservationsRequest.user) openUserInfoDialog();
                }}
                sx={{
                  color: reservationsRequest.user ? '#37597b !important' : 'rgba(0, 0, 0, 0.87)',
                  cursor: reservationsRequest.user ? 'pointer' : 'default',
                }}>
                {`${reservationsRequest.firstName} ${reservationsRequest.lastName}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2} alignSelf="center">
              <Typography className={classes.title}>Телефон:</Typography>
              <Typography className={classes.text}>
                {formatPhone(reservationsRequest.phone)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none'} }}>Дата:</Typography>
                <Typography className={classes.text}>
                  {formatStartEndDate(reservationsRequest.startDate, reservationsRequest.endDate)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: {xs: 2, sm: 0} }}>
                <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none'} }}>Статус:</Typography>
                <Box>
                  {reservationsRequest.status === ReservationRequestStatuses.Submitted && <Chip color={'success'} size="small" label="Підтверджено" icon={<DoneAll />} />}
                  {reservationsRequest.status === ReservationRequestStatuses.Pending && <Chip color={'info'} size="small" label="Очікується" icon={<AccessTimeOutlined />} />}
                  {reservationsRequest.status === ReservationRequestStatuses.Cancelled && <Chip color={'warning'} size="small" label="Скасовано" icon={<Close />} />}
                  {reservationsRequest.status === ReservationRequestStatuses.Rejected && <Chip color={'error'} size="small" label="Відхилено" icon={<ReportOutlined />} />}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2} alignSelf="center">
              <Typography className={classes.title}>Номер авто:</Typography>
              <Typography className={classes.text}>
                {reservationsRequest.carNumber || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={1} alignSelf="center">
              <Typography className={classes.title}>Сектор(и):</Typography>
              <Typography className={classes.text}>
                {reservationsRequest.sectors.join(', ')}
              </Typography>
            </Grid>
            <Grid item xs={2} md={1} alignSelf="center" sx={{ order: { xs: -1, md: 0 } }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title="Open context menu">
                  <IconButton aria-label="open context menu" onClick={openMenu}>
                    <MoreVert />
                  </IconButton>
                </Tooltip>
                <Menu>
                  <MenuItem onClick={openDialog}>Редагувати</MenuItem>
                  {reservationsRequest.status === ReservationRequestStatuses.Pending && (
                    [
                      <Divider key="divider" />,
                      <MenuItem onClick={markAsSubmitted} key="markAsSubmitted">Підтвердити</MenuItem>,
                      <MenuItem onClick={handleRejectReservation} key="handleRejectReservation">Відхилити</MenuItem>,
                    ]
                  )}
                  <Divider />
                  <MenuItem onClick={toggleRemoveModal} sx={{ color: '#D32F2F' }}>Видалити</MenuItem>
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Collapsable>
        <Collapse in={extended}>
          <Divider sx={{ p: 1 }} />
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                Повідомлення
              </Typography>
              <Typography className={classes.text}>
                {reservationsRequest.notes || ''}
              </Typography>
            </Grid>
          </Grid>
        </Collapse>
      </Card>
    </Fragment>
  );
}

export default ReservationRequestItem;

const useStyles = makeStyles({
  title: {
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
