import React from 'react';
import { Box } from '@mui/material';

const NoData:React.FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <img src='/images/noData.jpg' alt="No data" style={{ maxHeight: 'calc(100vh - 136px)', maxWidth: '100%', objectFit: 'cover' }} />
    </Box>
  );
}

export default NoData;
