import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import UsersAsync from 'store/users/usersAsync';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
// Components
import Title from 'components/Title';
import Phone from 'components/Phone';
import { Input } from 'components/Controls';
// MUI
import { LoadingButton } from '@mui/lab';
import {
  FormGroup, FormHelperText, Grid, Paper, TextField
} from '@mui/material';
import {
  Circle as CircleIcon,
  CancelOutlined as CancelOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
} from '@mui/icons-material';
// utilites
import {
  isEmail, isRequired, isMinValue, isUpperCase, isSpecial, isLowerCase, isMatch
} from 'utilities/Validation';

interface IForm {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  password?: string;
  rePassword?: string;
}

const ProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  const { handleSubmit, control, formState: {errors}, reset, watch } = useForm<IForm>({
    defaultValues: {
      email: currentUser?.email,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      phone: currentUser?.phone,
      password: '',
      rePassword: '',
    }
  });

  useEffect(() => {
    reset({
      email: currentUser?.email,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      phone: currentUser?.phone,
      password: '',
      rePassword: '',
    });
    // eslint-disable-next-line
  }, [currentUser])

  const onSubmit = handleSubmit((data: IForm) => {
    const { phone, password, ...nextData } = data;
    const newData: any = { ...nextData };
    if (phone) newData['phone'] = phone;
    if (password) newData['password'] = password;

    setIsLoading(true);
    dispatch(UsersAsync.updateUser({ userId: currentUser?._id, user: newData }))
      .unwrap()
      .finally(() => setIsLoading(false))
  });

  const watchPassword = watch('password');
  const watchRePassword = watch('rePassword');

  return (
    <Paper variant="outlined" sx={{ margin: '80px auto 0', maxWidth: '600px', p: 5}}>
      <Title>Профіль</Title>
      <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2} sx={{ pt: 4, pb: 4 }}>
            {/* email */}
            <Grid item xs={12}>
              <Controller
                control={control} name="email"
                rules={{ required: isRequired, pattern: isEmail }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="E-mail"
                    fullWidth
                    required
                    error={!!errors?.email}
                    helperText={errors?.email ? errors.email.message : null}
                  />
                )}
              />
            </Grid>
            {/* firstName */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="firstName"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Ім'я"
                    fullWidth
                    required
                    error={!!errors?.firstName}
                    helperText={errors?.firstName ? errors.firstName.message : null}
                  />
                )}
              />
            </Grid>
            {/* lastName */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="lastName"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Прізвище"
                    fullWidth
                    required
                    error={!!errors?.lastName}
                    helperText={errors?.lastName ? errors.lastName.message : null}
                  />
                )}
              />
            </Grid>
            {/* phone */}
            <Grid item xs={12}>
              <Controller
                control={control} name="phone"
                rules={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                  <Phone
                    value={value || ''}
                    onChange={onChange}
                    label="Телефон"
                    required
                    error={!!errors?.phone}
                    helperText={errors?.phone ? errors.phone.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control} name="password"
                rules={{
                  required: {
                    ...isRequired,
                    value: watchRePassword !== ''
                  },
                  validate: {
                    isMinValue: (value:string | undefined) => isMinValue(value, 8),
                    isLowerCase,
                    isUpperCase,
                    isSpecial
                  }
                }}
                render={({ field }) => (
                  <Input
                    { ...field }
                    fullWidth
                    label="Новий пароль"
                    type="password"
                    autoComplete="off"
                    required={watchRePassword !== ''}
                    error={Boolean(errors.password && watchRePassword !== '')}
                    helperText={errors.password && watchRePassword !== '' && errors.password.type === 'required' ? errors.password.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control} name="rePassword"
                rules={{
                  required: {
                    ...isRequired,
                    value: watchPassword !== ''
                  },
                  validate: {
                    isPasswordMatch: (value:string | undefined) => isMatch(value, watchPassword, 'Пароль не співпадає')
                  }
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    fullWidth
                    label="Підтвердити пароль"
                    type="password"
                    autoComplete="off"
                    required={watchPassword !== ''}
                    error={Boolean(errors.rePassword && watchPassword !== '')}
                    helperText={errors.rePassword && watchPassword !== '' ? errors.rePassword.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormHelperText sx={{
                  display: 'flex', gap: 0.5, alignItems: 'center',
                  color: !watchPassword ? 'gray' : isMinValue(watchPassword, 8) === true ? '#2E7D32' : '#C62828'
                }}>
                  
                  {!watchPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isMinValue(watchPassword, 8) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
                  Ваш пароль має містити 8 або більше символів
                </FormHelperText>
                <FormHelperText sx={{
                  display: 'flex', gap: 0.5, alignItems: 'center',
                  color: !watchPassword ? 'gray' : isUpperCase(watchPassword) === true && isLowerCase(watchPassword) === true ? '#2E7D32' : '#C62828'
                }}>
                  {!watchPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isUpperCase(watchPassword) === true && isLowerCase(watchPassword) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
                  Ваш пароль має містити 1 або більше великих та малих літер.
                </FormHelperText>
                <FormHelperText sx={{
                  display: 'flex', gap: 0.5, alignItems: 'center',
                  color: !watchPassword ? 'gray' : isSpecial(watchPassword) === true ? '#2E7D32' : '#C62828'
                }}>
                  {!watchPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isSpecial(watchPassword) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
                  Ваш пароль має містити 1 або більше спеціальних симолів.
                </FormHelperText>
              </FormGroup>
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            loading={isLoading}
            type='submit'
            variant='contained'
          >
            Зберегти
          </LoadingButton>
        </form>
    </Paper>
  );
};

export default ProfilePage;
