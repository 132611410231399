import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import authAsync from 'store/auth/authAsync';
// components
import Title from 'components/Title';
// mui
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { TextField, Grid, Box, Paper, IconButton } from '@mui/material';
import {
  RemoveRedEyeOutlined as RemoveRedEyeOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon
} from '@mui/icons-material';
// utilites
import { isEmail, isRequired } from 'utilities/Validation';

interface IForm {
  email: string;
  password: string;
}

const SignInPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [visiblePassword, setVisiblePassword] = useState(false);
  const handleVisiblePassword = () => setVisiblePassword(!visiblePassword);

  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm<IForm>({
    defaultValues: {
      email: '',
      password: '',
    }
  });

  const onSubmit = handleSubmit((data: IForm) => {
    setIsLoading(true);

    dispatch(authAsync.signIn(data))
      .unwrap()
      .finally(() => setIsLoading(false));
  });

  return (
    <Box className={classes.page}>
      <Paper elevation={5} sx={{ p:5, width: '600px' }}>
        <Title>Вхід</Title>
        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2} sx={{ pt: 4, pb: 4 }}>
            <Grid item xs={12}>
              <Controller
                control={control} name="email"
                rules={{ required: isRequired, pattern: isEmail }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="E-mail"
                    fullWidth
                    autoComplete="email"
                    required
                    error={!!errors?.email}
                    helperText={errors?.email ? errors.email.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control} name="password"
                rules={{  required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Пароль"
                    type={visiblePassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleVisiblePassword} aria-label={visiblePassword ? 'Hide password' : 'Show password'}>
                          {visiblePassword ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                        </IconButton>
                      )
                    }}
                    autoComplete="password"
                    fullWidth
                    required
                    error={!!errors?.password}
                    helperText={errors?.password ? errors.password.message : null}
                  />
                )}
              />
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            loading={isLoading}
            type='submit'
            variant='contained'
            color='primary'
          >Увійти</LoadingButton>
        </form>
      </Paper>
    </Box>
  );
}

export default SignInPage;

const useStyles = makeStyles({
  page: {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
