import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';
// Types
import UserRoles from 'types/UserRoles';
// Components
import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';
// Pages
import SignInPage from 'pages/signInPage';
import AdminPage from 'pages/AdminPage';
import { selectCurrentUser } from 'store/users/usersSelectors';
import ManagerPage from 'pages/ManagerPage';

const AppRouting: React.FC = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser)

  const navigateTo = isAuthenticated && currentUser ? `/${currentUser.role}` : '/sign-in';

  return (
    <Routes>
      <Route path="/sign-in" element={
        <PublicRoute>
          <SignInPage />
        </PublicRoute>
      } />

      {currentUser?.role === UserRoles.Admin && (
        <Route path="/admin/*" element={
          <PrivateRoute>
            <AdminPage />
          </PrivateRoute>
        } />
      )}

      {currentUser?.role === UserRoles.Manager && (
        <Route path="/manager/*" element={
          <PrivateRoute>
            <ManagerPage />
          </PrivateRoute>
        } />
      )}

      <Route path="*" element={<Navigate to={navigateTo} />} />
    </Routes>
  )
}

export default AppRouting;
