import React from 'react';
import { useSelector } from 'react-redux';
// Hooks
import useDialog from 'hooks/useDialog';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import CompetitionsAsync from 'store/competitions/competitionsAsync';
// Selectors
import { selectParams } from 'store/competitions/competitionsSelectors';
// Mui
import { Button, Box, TextField, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// Components
import Title from 'components/Title';
import CompetitionsForm from './CompetitionsForm';

const CompetitionsHeader:React.FC = () => {
  const dispatch = useAppDispatch();

  const params = useSelector(selectParams);

  const handleSortBy = (e:any) => {
    const { value: sortBy } = e.target;
    dispatch(CompetitionsAsync.fetchCompetitions({ ...params, sortBy }));
  }

  const { Dialog, openDialog, closeDialog } = useDialog();

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
        <Title>Історія змагань</Title>
        <Box sx={{ width: { xs: '100%', md: 'auto' }, display: 'flex', flexWrap: 'wrap', gap: 1, pt: { xs: 2, md: 0 } }}>
          <TextField
            sx={{ minWidth: { xs: '100%', md: '220px' } }}
            select
            value={params.sortBy}
            onChange={handleSortBy}
            size="small"
            label="Сортувати за"
          >
            <MenuItem value="createdDate">Дата створення</MenuItem>
            <MenuItem value="startDate">Дата початку</MenuItem>
          </TextField>
          <Button
            sx={{ minWidth: { xs: '100%', md: 'auto' } }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={openDialog}
          >
            Додати змагання
          </Button>
        </Box>
      </Box>
      <Dialog maxWidth="sm">
        <CompetitionsForm onClose={closeDialog} />
      </Dialog>
    </React.Fragment>
  );
};

export default CompetitionsHeader;
