import React from 'react';
import SponsorsHeader from './SponsorsHeader';
import SponsorsList from './SponsorsList';

const SponsorsPage:React.FC = () => {
  return (
    <React.Fragment>
      <SponsorsHeader />
      <SponsorsList />
    </React.Fragment>
  );
}

export default SponsorsPage;
