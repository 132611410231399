import React from 'react';
// Models
import ITemplate from 'models/Template';
import {
  IconButton, ListItem, ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
// Icons
import { Edit as EditIcon } from '@mui/icons-material';
// Dialog
import TemplatesForm from './TemplatesForm';

type Props = {
  template: ITemplate;
}

const TemplateItem:React.FC<Props> = ({ template }) => {
  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const toggleDialogOpen = () => setDialogOpen((prevState:boolean) => !prevState);

  return (
    <React.Fragment>
      <ListItem>
        <ListItemText
          primary={template.title}
        ></ListItemText>
        <ListItemSecondaryAction>
          <IconButton
            onClick={toggleDialogOpen}
          ><EditIcon /></IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      {dialogOpen ? (
        <TemplatesForm
          open={dialogOpen}
          onClose={toggleDialogOpen}
          template={template}
        />
      ) : null}
    </React.Fragment>
  )
}

export default TemplateItem;
