import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import useDialog from 'hooks/useDialog';
// Async
import SponsorsAsync from 'store/sponsors/sponsorsAsync';
// Selectors
import { selectReorderMode } from 'store/sponsors/sponsorsSelectors';
// Models
import ISponsor from 'models/Sponsor';
// Components
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import SponsorsForm from './SponsorsForm';
// MUI
import {
  Card, Grid, IconButton, Typography
} from '@mui/material';
import { EditOutlined, DeleteOutlined, DragIndicatorOutlined } from '@mui/icons-material';

type Props = {
  sponsor: ISponsor,
}

const SponsorItem:React.FC<Props> = ({ sponsor }) => {
  const dispatch = useAppDispatch();

  const reorderMode = useSelector(selectReorderMode);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(!openDeleteModal);
  
  const handleRemove = () => {
    dispatch(SponsorsAsync.deleteSponsor(sponsor._id));
  }

  const { Dialog, openDialog, closeDialog } = useDialog();

  return (
    <React.Fragment>
      <ConfirmDeleteModal open={openDeleteModal} onClose={handleOpenDeleteModal} handleRemove={handleRemove} title="спонсора" />
      <Dialog maxWidth="sm">
        <SponsorsForm sponsor={sponsor} onClose={closeDialog} />
      </Dialog>

      <Card sx={{ p: 2, width: '100%', borderBottom: '1px solid #eee', borderRadius: 0 }}>
        <Grid container spacing={2}>
          {reorderMode && (
            <Grid item xs={3} sm={2} md={1} alignSelf="center">
              <IconButton size="large">
                <DragIndicatorOutlined fontSize="large" />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={reorderMode ? 9 : 12} sm={5} md={3}>
            <img src={sponsor.logo?.path} alt={sponsor.name} style={{ height: '140px', objectFit: 'cover' }} />
          </Grid>
          <Grid item xs={10} sm={reorderMode ? 3 : 5} md={reorderMode ? 6 : 7} alignSelf="center">
            <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{sponsor.name}</Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
            <IconButton
              onClick={openDialog}
            >
              <EditOutlined />
            </IconButton>
            <IconButton
              onClick={handleOpenDeleteModal}
              color="error"
            >
              <DeleteOutlined />
            </IconButton>
          </Grid>
        </Grid>
        {/* <CardMedia component="img" image={sponsor.logo?.path} sx={{ width: '100%', height: '140px', objectFit: 'cover' }} />
        <Divider sx={{ mt: 2 }} />
        <CardContent>
          <Typography variant="h5" textAlign="center">{sponsor.name}</Typography>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="outlined"
            onClick={openDialog}
          >Редагувати</Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleOpenDeleteModal}
          >Видалити</Button>
        </CardActions> */}
      </Card>
    </React.Fragment>
  );
}

export default SponsorItem;
