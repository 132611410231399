import React from 'react';
import { useSelector } from "react-redux";
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import ReservationsRequestsAsync from 'store/reservationsRequests/reservationsRequestsAsync';
// Models
import IReservationRequest from "models/ReservationRequest";
// Selectors
import { selectReservationsRequests, selectParams, selectTotal } from "store/reservationsRequests/reservationsRequestsSelectors";
// Components
import ReservationsRequestItem from "./ReservationRequestItem";
import NoData from "components/NoData";
// mui
import { Box, LinearProgress, TablePagination } from "@mui/material";
import { makeStyles } from '@mui/styles';

const ReservationsRequestsList:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const reservationsRequests = useSelector(selectReservationsRequests);
  const total = useSelector(selectTotal);
  const params = useSelector(selectParams);

  const handleChangePage = (_:any, page: number) => {
    dispatch(ReservationsRequestsAsync.fetchReservationsRequests({ ...params, page: page + 1 }));
  }

  const handleChangeLimit = (e:any) => {
    const { value: limit } = e.target;
    dispatch(ReservationsRequestsAsync.fetchReservationsRequests({ ...params, page: 1, limit, }));
  }

  if (!reservationsRequests) return <LinearProgress sx={{ mt: 2 }} />;
  if (reservationsRequests && !reservationsRequests.length) return <NoData />

  return (
    <Box className={classes.list} sx={{ mt: 3 }}>
      <Box className={classes.items}>
        {reservationsRequests?.map((reservationsRequest: IReservationRequest) => (
          <ReservationsRequestItem key={reservationsRequest._id} reservationsRequest={reservationsRequest} />
        ))}
      </Box>
      <Box>
        <TablePagination
          className={classes.pagination}
          component="div"
          count={total}
          page={params.page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={params.limit}
          onRowsPerPageChange={handleChangeLimit}
          labelRowsPerPage="Показувати"
          labelDisplayedRows={({from, to, count}) => `${from}–${to} з ${count}`}
          rowsPerPageOptions={[20, 50, 100]}
        />
      </Box>
    </Box>
  );
}

export default ReservationsRequestsList;

const useStyles = makeStyles({
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 208px)',
    },
  },
  pagination: {
    boxShadow: '0px -3px 6px -1px rgb(0 0 0 / 8%)',
  },
});
