import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ISponsor from "models/Sponsor";
import SponsorsAsync from "./sponsorsAsync";

interface IState {
  sponsors: ISponsor[];
  reorderMode: boolean;
}

const initialState: IState = {
  sponsors: [],
  reorderMode: false,
}

const sponsorsSlice = createSlice({
  name: 'sponsors',
  initialState,
  reducers: {
    setReorderMode: (state, action:PayloadAction<boolean>) => {
      state.reorderMode = action.payload;
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch sponsors
      .addCase(SponsorsAsync.fetchSponsors.fulfilled, (state, action) => {
        state.sponsors = action.payload;
      })
      // create sponsor
      .addCase(SponsorsAsync.createSponsor.fulfilled, (state, action) => {
        state.sponsors = [action.payload, ...state.sponsors];
      })
      // delete sponsor
      .addCase(SponsorsAsync.deleteSponsor.fulfilled, (state, action) => {
        const { arg:sponsorId } = action.meta;
        state.sponsors = state.sponsors.filter(sponsor => sponsor._id !== sponsorId);
      })
      // update sponsor
      .addCase(SponsorsAsync.updateSponsor.fulfilled, (state, action) => {
        state.sponsors = state.sponsors.map(sponsor => sponsor._id === action.payload._id ? action.payload : sponsor);
      })
      // reorder sponsors
      .addCase(SponsorsAsync.reorderSponsors.fulfilled, (state, action) => {
        state.sponsors = action.payload;
      })
  }
})

export const sponsorsActions = sponsorsSlice.actions;
export default sponsorsSlice.reducer;