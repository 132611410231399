import React from 'react';
// components
import CompetitionsHeader from './CompetitionsHeader';
import CompetitionsList from './CompetitionsList';

const CompetitionsPage:React.FC = () => {
  return (
    <React.Fragment>
      <CompetitionsHeader />
      <CompetitionsList />
    </React.Fragment>
  )
}

export default CompetitionsPage;
