import { createAsyncThunk } from "@reduxjs/toolkit";
// Models
import ICredential from "models/Credential";
// Async
import UsersAsync from "store/users/usersAsync";
// Actions
import { authActions } from "./authSlice";
import { usersActions } from "store/users/usersSlice";
// Services
import StorageService from "services/Storage.service";
// Utilities
import HttpClient from "utilities/HttpClient";

const url = '/auth';
const http:HttpClient = new HttpClient();

const AuthAsync = {
  signIn: createAsyncThunk('auth/signIn', async (data:any, thunkApi) => {
    const { parseBody } = await http.post(`${url}/login`, data);
      StorageService.setCredential(parseBody as ICredential);
      thunkApi.dispatch(UsersAsync.fetchMe());
  }),
  checkIsAuthorization: createAsyncThunk('auth/checkIsAuthorization', async (_, thunkApi) => {
    if ( StorageService.getRefreshToken() ){
      thunkApi.dispatch(UsersAsync.fetchMe());
    } else {
      StorageService.removeCredential();
      thunkApi.dispatch(authActions.setAuthenticated(false));
    }
  }),
  signOut: createAsyncThunk('auth/signOut', async (_, thunkApi) => {
    StorageService.removeCredential();
    thunkApi.dispatch(usersActions.removeCurrentUser());
    thunkApi.dispatch(authActions.setAuthenticated(false));
  }),
}

export default AuthAsync;
