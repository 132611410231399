import { FC, useEffect } from 'react';
import { useSelector } from "react-redux";
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import TemplatesAsync from 'store/templates/templatesAsync';
// Selectors
import { selectTemplates } from 'store/templates/templatesSelectors';
// Components
import TemplateItem from "./TemplateItem";
import NoData from 'components/NoData';
// Mui
import { Container, List, Paper, LinearProgress } from '@mui/material';

const TemplatesList:FC = () => {
  const dispatch = useAppDispatch();

  const templates = useSelector(selectTemplates);

  useEffect(() => {
    dispatch(TemplatesAsync.fetchTemplates());
    // eslint-disable-next-line
  }, []);

  if (!templates) return <LinearProgress sx={{ mt: 2 }} />;
  if (templates && !templates.length) return <NoData />

  return (
    <Container maxWidth="md" sx={{ pt: 4 }}>
      <Paper variant="outlined">
        <List>
          {templates.map((template) => (
            <TemplateItem
              key={template._id}
              template={template}
            />
          ))}
        </List>
      </Paper>
    </Container>
  );
}

export default TemplatesList;
