import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// Redux
import { Provider } from 'react-redux';
import { setupStore } from './store';
// MUI
import { SnackbarProvider } from 'notistack';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import uk from 'dayjs/locale/uk';
import DayJsUtils from '@date-io/dayjs';
import { ukUA } from 'locales/ukUA';

import App from './App/App';
import theme from './theme';
import './index.css';

const store = setupStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // In strict mode, actions are duplicated
  // <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <SnackbarProvider maxSnack={5}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              utils={DayJsUtils}
              adapterLocale={uk}
              localeText={ukUA.components.MuiLocalizationProvider.defaultProps.localeText}
            >
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </LocalizationProvider>
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  // </React.StrictMode>
);
