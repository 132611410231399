import React from 'react';
import { useSelector } from 'react-redux';
// mui
import { Box, Grid } from '@mui/material';
// Selectors
import { selectParticipants } from 'store/random/randomSelectors';
// Components
import RandomItem from './RandomItem';

const RandomList:React.FC = () => {
  const participants = useSelector(selectParticipants);

  if (!participants.length) return null;
  return (
    <Box sx={{
      mt: 4, border: '1px solid #eee', p: 2,
    }}>
      <Grid container spacing={2}>
        {participants.map(participant => (
          <Grid item xs={12} md={6} key={participant}>
            <RandomItem participant={participant} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default RandomList;
