import React from 'react';
import PricesHeader from './PricesHeader';
import PricesList from './PricesList';

const PricesPage:React.FC = () => {
  return (
    <React.Fragment>
      <PricesHeader />
      <PricesList />
    </React.Fragment>
  );
}

export default PricesPage;
