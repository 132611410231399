import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
// Hooks
import useDialog from 'hooks/useDialog';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import ReservationsRequestsAsync from 'store/reservationsRequests/reservationsRequestsAsync';
// Selectors
import { selectParams } from 'store/reservationsRequests/reservationsRequestsSelectors';
// Mui
import { MobileDatePicker } from '@mui/x-date-pickers';
import { Button, Box, TextField, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// Components
import Title from 'components/Title';
import ReservationsRequestsForm from './ReservationsRequestsForm';
import { reservationsRequestsActions } from 'store/reservationsRequests/reservationsRequestsSlice';

const ReservationsRequestsHeader:React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useSelector(selectParams);

  const [dateFilter, setDateFilter] = useState({
    startDate: params.startDate || null,
    endDate: params.endDate || null,
  });

  const handleDateFilter = (date: any, field: string) => {
    setDateFilter(prev => ({
      ...prev,
      [field]: date ? dayjs(date).format('YYYY-MM-DD') : null,
    }))
  }

  const handleSortBy = (e:any) => {
    const { value: sortBy } = e.target;
    dispatch(ReservationsRequestsAsync.fetchReservationsRequests({ ...params, sortBy }));
  }

  useEffect(() => {
    dispatch(ReservationsRequestsAsync.fetchReservationsRequests({...params, ...dateFilter}));
    // eslint-disable-next-line
  }, [dateFilter]);

  useEffect(() => {
    return () => {
      dispatch(reservationsRequestsActions.setInitialField('params'));
    }
    // eslint-disable-next-line
  }, []);

  const { Dialog, openDialog, closeDialog } = useDialog();

  return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <ReservationsRequestsForm onClose={closeDialog} />
      </Dialog>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
        <Title>Запити на бронювання</Title>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, pt: { xs: 2, lg: 0 } }}>
          <MobileDatePicker
            componentsProps={{
              actionBar: {
                actions: ['clear', 'cancel', 'accept'],
              },
            }}
            label="Дата заїзду"
            value={dateFilter.startDate || null}
            onChange={(date:any) => handleDateFilter(date, 'startDate')}
            renderInput={(params: any) => <TextField {...params} size="small" sx={{ minWidth: { xs: '100%', lg: 'auto' } }} />}
          />
          <MobileDatePicker
            componentsProps={{
              actionBar: {
                actions: ['clear', 'cancel', 'accept'],
              },
            }}
            label="Дата виїзду"
            value={dateFilter.endDate || null}
            onChange={(date:any) => handleDateFilter(date, 'endDate')}
            renderInput={(params: any) => <TextField {...params} size="small" sx={{ minWidth: { xs: '100%', lg: 'auto' } }} />}
          />
          <TextField
            sx={{ minWidth: { xs: '100%', lg: '220px' } }}
            select
            value={params.sortBy}
            onChange={handleSortBy}
            size="small"
            label="Сортувати за"
          >
            <MenuItem value="createdDate">Дата створення</MenuItem>
            <MenuItem value="startDate">Дата заїзду</MenuItem>
          </TextField>
          <Button
            sx={{ minWidth: { xs: '100%', lg: 'auto' } }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={openDialog}
          >
            Додати
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ReservationsRequestsHeader;
