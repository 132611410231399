import { FC, useMemo, useEffect, Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { appActions } from 'store/app/appSlice';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';
import { selectDrawerOpen } from 'store/app/appSelectors';
import { selectCurrentUser } from 'store/users/usersSelectors';
// Types
import UserRoles from 'types/UserRoles';
// Mui
import {
  Theme, Backdrop, Box, IconButton,
  List, ListItem, ListItemButton, ListItemIcon, ListItemText
} from '@mui/material';
// Icons
import {
  SvgIconComponent,
  ListAltOutlined as ListAltOutlinedIcon,
  PeopleOutlined as PeopleOutlinedIcon,
  ShuffleOutlined as ShuffleOutlinedIcon,
  TransferWithinAStationOutlined as TransferWithinAStationOutlinedIcon,
  EmojiEventsOutlined as EmojiEventsOutlinedIcon,
  PaidOutlined as PaidOutlinedIcon,
  PostAddOutlined as PostAddOutlinedIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

interface INav {
  to: string;
  label: string;
  roles: UserRoles[];
  icon: SvgIconComponent;
}

const Drawer:FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const drawerOpen = useSelector(selectDrawerOpen);
  const isAuthenticated:boolean | null = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser);

  const navs:{ [key:string]:INav[] } = useMemo(() => {
    return {
      'База даних': [
        { to: `/${currentUser?.role}/admins`, label: 'Адміністратори', roles: [UserRoles.Admin], icon: PeopleOutlinedIcon },
        { to: `/${currentUser?.role}/clients`, label: 'Клієнти', roles: [UserRoles.Admin], icon: PeopleOutlinedIcon },
        { to: `/${currentUser?.role}/reservationsRequests`, label: 'Запити на бронювання', roles: [UserRoles.Admin, UserRoles.Manager], icon: ListAltOutlinedIcon },
        { to: `/${currentUser?.role}/competitions`, label: 'Змагання', roles: [UserRoles.Admin], icon: EmojiEventsOutlinedIcon },
      ],
      'Публічний сайт': [
        { to: `/${currentUser?.role}/sponsors`, label: 'Спонсори', roles: [UserRoles.Admin], icon: TransferWithinAStationOutlinedIcon },
        { to: `/${currentUser?.role}/prices`, label: 'Ціни', roles: [UserRoles.Admin], icon: PaidOutlinedIcon },
        { to: `/${currentUser?.role}/templates`, label: 'Шаблони', roles: [UserRoles.Admin], icon: PostAddOutlinedIcon },
      ],
      'Інше': [
        { to: `/${currentUser?.role}/random`, label: 'Жеребкування', roles: [UserRoles.Admin], icon: ShuffleOutlinedIcon },
      ]
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( isAuthenticated && window.matchMedia('(min-width: 1440px)').matches && !drawerOpen ){
      handleToggleDrawer();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const handleToggleDrawer = () => dispatch(appActions.toggleDrawer());

  if ( !currentUser ) return null;
  return (
    <Fragment>
      <Backdrop
        open={drawerOpen}
        onClick={handleToggleDrawer}
        sx={{
          display: { sm: 'none' },
          zIndex: (theme:Theme) => theme.zIndex.drawer - 1
        }}
      />
      <Box
        sx={{
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          position: { xs: 'fixed', sm: 'relative' },
          top: 0, left: 0,
          width: {
            xs: '256px',
            sm: drawerOpen ? '256px' : '72px'
          },
          minHeight: 'calc(100vh - 64px)',
          height: '100%',
          bgcolor: 'white',
          borderRight: '1px solid rgba(0,0,0,0.12)',
          transform: {
            xs: drawerOpen ? 'initial' : 'translateX(-100%)',
            sm: 'translateX(0)'
          },
          '@media print': {
            display: 'none',
          },
          zIndex: (theme:Theme) => theme.zIndex.drawer
        }}
      >
        <Box
          sx={{
            display: {
              sm: 'none'
            },
            p: 2,
            textAlign: 'right'
          }}
        >
          <IconButton
            color="primary"
            onClick={handleToggleDrawer}
          ><CloseIcon /></IconButton>
        </Box>
        <List
          sx={{
            flexGrow: 1,
            overflowY: 'auto'
          }}
        >
          {Object.keys(navs).map((key:string) => (
            <Box key={`nav-item-${key}`}>
              <ListItemText
                sx={{
                  display: {
                    sm: drawerOpen ? 'block' : 'none'
                  },
                  color: 'rgba(0,0,0,0.6)',
                  fontSize: '14px',
                  fontWeight: 500,
                  letterSpacing: '-0.4px',
                  padding: '7px 16px',
                  textTransform: 'capitalize'
                }}
                primary={key}
              />
              <List
                sx={{
                  borderBottom: '1px solid rgba(0,0,0,0.12)',
                  pt: 0,
                  pb: 0
                }}
              >
                {navs[key].map((nav:INav, index:number) => {
                  const Icon = nav.icon;
                  const selected = pathname.startsWith(nav.to);
                  if ( !nav.roles.includes(currentUser.role) ) return null;
                  return (
                    <ListItem key={`nav-item-${key}-${index}`} disablePadding>
                      <ListItemButton
                        component={NavLink}
                        to={nav.to}
                        selected={selected}
                        sx={(theme) => ({
                          justifyContent: {
                            sm: drawerOpen ? 'flex-start' : 'center'
                          },
                          color: selected ? theme.palette.primary.main : 'rgba(0,0,0,0.87)',
                          lineHeight: '24px',
                          letterSpacing: '-0.35px',
                          pt: { sm: drawerOpen ? '8px' : '12px' },
                          pb: { sm: drawerOpen ? '8px' : '12px' }
                        })}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: { xs: '48px', sm: drawerOpen ? '48px' : '24px' }
                          }}
                        >
                          <Icon color={selected ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            display: { sm: drawerOpen ? 'block' : 'none' }
                          }}
                          primary={nav.label}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          ))}
        </List>
      </Box>
    </Fragment>
  );
};

export default Drawer;
