// Mui
import { createTheme } from '@mui/material/styles';
import { ukUA } from 'locales/ukUA';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Work Sans',
      'sans-serif',
      '-apple-system'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#262E36',
      light: 'rgba(38, 46, 54, 0.12)'
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(315deg, #262E36 0%, #37597b 100%)',
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: 600,
          textTransform: 'capitalize'
        },
        contained: {
          background: 'linear-gradient(315deg, #262E36 0%, #37597b 100%)',
          color: 'white',
          transition: 'initial',
          '&:hover': {
            background: '#37597b'
          },
          '&:disabled': {
            background: 'rgba(0,0,0,0.12)'
          }
        },
      }
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            border: `1px solid rgba(38, 46, 54,0.5)`,
            padding: '7px',
            '&:hover': {
              backgroundColor: 'rgba(38, 46, 54,0.1)'
            },
            '&:disabled': {
              borderColor: 'rgba(0,0,0,0.12)'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          borderRadius: '4px'
        }
      }
    }
  },
}, ukUA);

export default theme;