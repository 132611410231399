import { createAsyncThunk } from "@reduxjs/toolkit";
// Models
import ICompetition from "models/Competition";
// Actions
import { appActions } from "store/app/appSlice";
// Utilities
import HttpClient from "utilities/HttpClient";

const url = '/competitions';
const http:HttpClient = new HttpClient();

const CompetitionsAsync = {
  fetchCompetitions: createAsyncThunk('competitions/fetchCompetitions', async (params:any) => {
    const { parseBody } = await http.get(url, params)
    return parseBody;
  }),
  createCompetition: createAsyncThunk('competitions/createCompetition', async (data:any, thunkApi) => {
    const { parseBody } = await http.post(url, data);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Змагання успішно створено' }));
    return parseBody as ICompetition;
  }),
  updateCompetition: createAsyncThunk('competitions/updateCompetition', async (data:any, thunkApi) => {
    const { competitionId, competitionData } = data;
    const { parseBody } = await http.put(`${url}/${competitionId}`, competitionData);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Змагання успішно оновлено' }));
    return parseBody as ICompetition;
  }),
  deleteCompetition: createAsyncThunk('competitions/deleteCompetition', async (competitionId:string, thunkApi) => {
    await http.delete(`${url}/${competitionId}`);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Змагання було видалено' }));
  })
}

export default CompetitionsAsync;
