import React from "react";
import domtoimage from 'dom-to-image';
// mui
import { IconButton } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const Screenshot:React.FC = () => {
  const exportPNG = () => {
    const content = document.getElementById('content');
    if(!content) return;
    domtoimage.toPng(content, { bgcolor: '#fff' })
    .then((dataUrl: any) => {
      var img = new Image();
      img.src = dataUrl;
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${Date.now()}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }

  return (
    <IconButton color="primary" sx={{ mr: 2 }} onClick={exportPNG}>
      <FileDownloadOutlinedIcon />
    </IconButton>
  )
}

export default Screenshot;
