import React, { useState } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import useDialog from 'hooks/useDialog';
// Async
import PricesAsync from 'store/prices/pricesAsync';
// Models
import IPrice from 'models/Price';
// Components
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import PricesForm from './PricesForm';
// MUI
import {
  Button, Card, CardActions, CardContent,
  CardMedia, Divider, Tooltip, Typography
} from '@mui/material';

type Props = {
  price: IPrice,
}

const PriceItem:React.FC<Props> = ({ price }) => {
  const dispatch = useAppDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(!openDeleteModal);
  
  const handleRemove = () => {
    dispatch(PricesAsync.deletePrice(price._id));
  }

  const { Dialog, openDialog, closeDialog } = useDialog();

  return (
    <React.Fragment>
      <ConfirmDeleteModal open={openDeleteModal} onClose={handleOpenDeleteModal} handleRemove={handleRemove} title="ціну" />
      <Dialog maxWidth="sm">
        <PricesForm price={price} onClose={closeDialog} />
      </Dialog>

      <Card sx={{ p: 2, boxShadow: '0 0 8px 1px rgba(0,0,0,0.2)', opacity: price.enabled ? 1 : 0.6, width: { xs: '100%', sm: '350px' } }}>
        <CardMedia component="img" image={price.image?.path} sx={{ width: '100%', height: '200px', objectFit: 'cover' }} />
        <Divider sx={{ mt: 2 }} />
        <CardContent>
          <Tooltip title={price.title}>
            <Typography variant="h5" sx={{ textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{price.title}</Typography>
          </Tooltip>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="outlined"
            onClick={openDialog}
          >Редагувати</Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleOpenDeleteModal}
          >Видалити</Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
}

export default PriceItem;
