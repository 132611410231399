import React from 'react';
// components
import ReservationsRequestsHeader from './ReservationsRequestsHeader';
import ReservationsRequestsList from './ReservationsRequestsList';

const ReservationsRequestsPage:React.FC = () => {
  return (
    <React.Fragment>
      <ReservationsRequestsHeader />
      <ReservationsRequestsList />
    </React.Fragment>
  )
}

export default ReservationsRequestsPage;
