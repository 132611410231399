export const isRequired = {
  value: true,
  message: "Це поле є обов'язковим"
}

export const isEmail = {
  value: /^([a-z0-9_-_+]+\.)*[a-z0-9_-_+]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i,
  message: 'Не коректний e-mail'
}

export const isMatch = (value:string | undefined, matchField:string | undefined, message = 'Поля не співпадають') => {
  if ( !value || !matchField ) return true;
  return value === matchField || message;
}

export const isMinValue = (value:string | undefined, min:number) => {
  if ( !value ) return true;
  return value.length >= min || `Повинно бути ${min} або більше символів`;
};

export const isLowerCase = (value:string | undefined) => {
  if ( !value ) return true;
  return /[a-z]/.test(value) || 'Повинно бути 1 або більше малих літер';
};

export const isUpperCase = (value:string | undefined) => {
  if ( !value ) return true;
  return /[A-Z]/.test(value) || 'Повинно бути 1 або більше великих літер';
};

export const isSpecial = (value:string | undefined) => {
  if ( !value ) return true;
  // eslint-disable-next-line
  return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value) || 'Повинно бути 1 або більше \'спеціальних\' символів';
};
