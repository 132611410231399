import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
// Models
import IFile from "models/File";
// Utilities
import HttpClient from "utilities/HttpClient";
import imageCompression from "browser-image-compression";

const url = '/assets';
const http:HttpClient = new HttpClient();

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
}

const AssetsAsync = {
  createAsset: createAsyncThunk('assets/createAsset', async (files: IFile[], thunkApi) => {
    const formData = new FormData();

    const promises = Array.from(files).map(async (file: IFile) => {
      const compressedFile = await imageCompression(file.file, options);
      return ({ file: compressedFile, fileName: file.file.name });
    })

    return Promise.all(promises).then((data) => {
      data.forEach((file) => {
        formData.append('files', file.file, file.fileName);
      })
    }).then(() => {
      return http.post(url, formData)
    }).then(data => data.parseBody)
  }),
  validateAssets: createAsyncThunk('assets/validateAssets', async (_: any, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const { files, assets, assetsIdsToDelete } = state.assets;

    let result = [...assets];

    if (files.length) {
      const { payload }: any = await thunkApi.dispatch(AssetsAsync.createAsset(files));
      result = [...result, ...payload];
    }

    if (assetsIdsToDelete.length) {
      result = result.filter(asset => !assetsIdsToDelete.includes(asset._id));
    }
    return result;
  }),
}

export default AssetsAsync;
