import React from 'react';
// Hooks
import useDialog from 'hooks/useDialog';
// Mui
import { Button, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// Components
import Title from 'components/Title';
import PricesForm from './PricesForm';

const PricesHeader:React.FC = () => {

  const { Dialog, openDialog, closeDialog } = useDialog();
  
  return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <PricesForm onClose={closeDialog} />
      </Dialog>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title>Ціни</Title>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={openDialog}
          >
            Додати ціну
          </Button>
          <IconButton
            sx={{ display: { xs: 'inline-flex', md: 'none' } }}
            color="primary"
            onClick={openDialog}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PricesHeader;
