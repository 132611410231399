import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Selectors
import { selectSectors, selectStatus, selectParticipants } from 'store/random/randomSelectors';
// Actions
import { randomActions } from 'store/random/randomSlice';
import { appActions } from 'store/app/appSlice';
// Mui
import { Button, Box, TextField, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// Components
import Title from 'components/Title';
import Screenshot from 'components/Screenshot';

const RandomHeader:React.FC = () => {
  const dispatch = useAppDispatch();
  const sectors = useSelector(selectSectors);
  const status = useSelector(selectStatus);
  const participants = useSelector(selectParticipants);
  
  const [name, setName] = useState('');

  const handleChangeName = (e:any) => {
    const { value } = e.target;
    setName(value);
  }

  const addParticipant = () => {
    if (!name) return;
    if (participants.includes(name)) {
      dispatch(appActions.enqueueSnackbar({ message: 'Такий участик вже є' }))
    } else {
      dispatch(randomActions.addParticipants(name));
      setName('');
    }
  }

  const handleSectors = (e:any) => {
    const { value } = e.target;
    dispatch(randomActions.setSectors(value));
  }

  const random = () => {
    dispatch(randomActions.random());
  }

  useEffect(() => {
    return () => {
      dispatch(randomActions.setInitialField('participants'));
      dispatch(randomActions.setInitialField('sectors'));
      dispatch(randomActions.setInitialField('status'));
    }
    // eslint-disable-next-line
  }, []);
  
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <Title>Жеребкування</Title>
        {status ? (
          <Screenshot />
        ) : (
          !!participants.length && (
            <Button
              variant="outlined"
              onClick={random}
            >
              Визначити
            </Button>
          )
        )}
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', pt: { xs: 2, lg: 0 } }}>
          <TextField
            sx={{ width: { xs: 'calc(100vw - 32px)', sm: '220px' } }}
            select
            size="small"
            label="Сектори"
            value={sectors}
            onChange={handleSectors}
            SelectProps={{
              multiple: true,
            }}
          >
            {Array.from({length: 21}, (_, i) => i).map(sector => (
              <MenuItem key={sector} value={sector}>{sector}</MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            label="Ім'я"
            size="small"
            placeholder="Введіть ім'я або назву команди"
            value={name}
            onChange={handleChangeName}
          />
          <Button
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={addParticipant}
          >
            Додати учасника
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default RandomHeader;
