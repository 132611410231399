import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import SponsorsAsync from 'store/sponsors/sponsorsAsync';
// Selectors
import { selectReorderMode, selectSponsors } from 'store/sponsors/sponsorsSelectors';
// Components
import SponsorItem from './SponsorItem';
// MUI
import { Box } from '@mui/material';
// framer-motion
import { Reorder } from 'framer-motion';

const SponsorsList:React.FC = () => {
  const dispatch = useAppDispatch();
  const sponsors = useSelector(selectSponsors);
  const reorderMode = useSelector(selectReorderMode);

  const [firstRender, setFirstRender] = useState(true);
  const [items, setItems] = useState(sponsors);

  useEffect(() => {
    dispatch(SponsorsAsync.fetchSponsors());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setItems(sponsors);
    // eslint-disable-next-line
  }, [sponsors]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }

    if (!reorderMode) {
      dispatch(SponsorsAsync.reorderSponsors(items.map(item => item._id)));
    }
    // eslint-disable-next-line
  }, [reorderMode]);

  return (
    <Box sx={{
      mt: 3, 
      backgroundColor: '#fff',
      borderRadius: '4px',
      boxShadow: '0px 0px 0px 1px #E0E0E0'
    }}>
      <Reorder.Group
        axis="y" as="div"
        values={items}
        onReorder={setItems}
        style={{ marginTop: '32px' }}
      >
        {items.map(sponsor => (
          <Reorder.Item key={sponsor._id} value={sponsor} as="div" dragListener={reorderMode}>
            <SponsorItem sponsor={sponsor} />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </Box>
  );
}

export default SponsorsList;
