import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
// hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import AuthAsync from 'store/auth/authAsync';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';
import { selectCurrentUser } from 'store/users/usersSelectors';
// components
import Header from 'components/Header';
import AppRouting from './App.routing';
import Notifications from 'components/Notifications';
// Mui
import { LinearProgress } from '@mui/material';

const App = () => {
  const dispatch = useAppDispatch();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    dispatch(AuthAsync.checkIsAuthorization());
    // eslint-disable-next-line
  }, []);

  if (isAuthenticated === null || (isAuthenticated && !currentUser)) return <LinearProgress />;

  return (
    <Fragment>
      <Header />
      <AppRouting />
      <Notifications />
    </Fragment>
  );
}

export default App;
