import React from 'react';
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';

type Props = {
  children: JSX.Element;
}

const PrivateRoute:React.FC<Props> = ({ children }) => {
  const isAuthenticated:boolean | null = useSelector(selectIsAuthenticated);

  if ( !isAuthenticated ) return <Navigate to="/sign-in" replace />;

  return children;
}

export default PrivateRoute;
