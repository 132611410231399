import { createAsyncThunk } from "@reduxjs/toolkit";
// Models
import IPrice from "models/Price";
// Async
import AssetsAsync from 'store/assets/assetsAsync';
// Actions
import { appActions } from "store/app/appSlice";
// Utilities
import HttpClient from "utilities/HttpClient";

const url = '/prices';
const http:HttpClient = new HttpClient();

const PricesAsync = {
  fetchPrices: createAsyncThunk('prices/fetchPrices', async () => {
    const { parseBody } = await http.get(url);
    return parseBody as IPrice[];
  }),
  createPrice: createAsyncThunk('prices/createPrice', async (price:any , thunkApi: any) => {
    const nextData = { ...price };

    const { payload: images } : any = await thunkApi.dispatch(AssetsAsync.validateAssets({}));

    if (images.length) {
      nextData['imageId'] = images[0]._id;
    }

    const { parseBody } = await http.post(url, nextData);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Ціну упішно додано' }));
    return parseBody as IPrice;
  }),
  deletePrice: createAsyncThunk('prices/deletePrice', async (priceId:string, thunkApi) => {
    await http.delete(`${url}/${priceId}`);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Ціну було видалено' }));
  }),
  updatePrice: createAsyncThunk('prices/updatePrice', async (data:any , thunkApi: any) => {
    const { price, priceId } = data;
    const nextData = { ...price };

    const { payload: images } : any = await thunkApi.dispatch(AssetsAsync.validateAssets({}));

    if (images.length) {
      nextData['imageId'] = images[0]._id;
    }
  
    const { parseBody } = await http.put(`${url}/${priceId}`, nextData);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Ціну упішно оновлено' }));
    return parseBody as IPrice;
  })
}

export default PricesAsync;
