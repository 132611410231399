import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Actions
import { randomActions } from 'store/random/randomSlice';
// Selectors
import { selectStatus } from 'store/random/randomSelectors';
// MUI
import { Card, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  participant: string,
}

const RandomItem:React.FC<Props> = ({ participant }) => {
  const dispatch = useAppDispatch();
  
  const status = useSelector(selectStatus);

  const removeParticipant = () => {
    dispatch(randomActions.removeParticipants(participant));
  }

  return (
    <Card sx={{ minHeight: '50px', padding: '5px 15px', boxShadow: '0 0 4px 1px rgba(0,0,0,0.2)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography>{participant}</Typography>
      {!status && (
        <IconButton onClick={removeParticipant}>
          <DeleteIcon />
        </IconButton>
      )}
    </Card>
  );
}

export default RandomItem;
