import React from 'react';
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import useDialog from 'hooks/useDialog';
// Actions
import { sponsorsActions } from 'store/sponsors/sponsorsSlice';
// Selectors
import { selectReorderMode } from 'store/sponsors/sponsorsSelectors';
// Mui
import { Button, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// Components
import Title from 'components/Title';
import SponsorsForm from './SponsorsForm';

const SponsorsHeader:React.FC = () => {
  const dispatch = useAppDispatch();

  const { Dialog, openDialog, closeDialog } = useDialog();

  const reorderMode = useSelector(selectReorderMode);

  const changeReorderMode = (value: boolean) => {
    dispatch(sponsorsActions.setReorderMode(value));
  }

  return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <SponsorsForm onClose={closeDialog} />
      </Dialog>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title>Спонсори</Title>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            onClick={() => changeReorderMode(!reorderMode)}
          >
            {`${reorderMode ? 'Зберегти' : 'Посортувати'}`}
          </Button>
          <Button
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={openDialog}
          >
            Додати спонсора
          </Button>
          <IconButton
            sx={{ display: { xs: 'inline-flex', md: 'none' } }}
            color="primary"
            onClick={openDialog}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SponsorsHeader;
