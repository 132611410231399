import React from 'react';
// Mui
import { Dialog as MuiDialog, DialogProps } from '@mui/material';

const useDialog = () => {
  const [ open, setOpen ] = React.useState<boolean>(false);

  const openDialog = React.useCallback(() => {
    setOpen(true);
    // eslint-disable-nextline
  }, []);

  const closeDialog = React.useCallback(() => {
    setOpen(false);
    // eslint-disable-nextline
  }, []);

  const Dialog = React.useMemo(() => {
    const DialogComponent:React.FC<Omit<DialogProps, 'open' | 'onClose'>>  = ({ children, ...props }) => {
      return (
        <MuiDialog
          {...props}
          open={open}
          onClose={closeDialog}
          fullWidth
        >{children}</MuiDialog>
      )
    }
    return DialogComponent;
    // eslint-disable-next-line
  }, [open]);

  return {
    Dialog,
    openDialog,
    closeDialog
  }
}


export default useDialog;