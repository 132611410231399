import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IReservationRequest from "models/ReservationRequest";
import ReservationsRequestsAsync from "./reservationsRequestsAsync";

interface IState {
  reservationsRequests: IReservationRequest[] | null;
  total: number;
  params: any;
}

const initialState: IState = {
  reservationsRequests: null,
  total: 0,
  params: {
    limit: 20,
    page: 1,
    sortBy: 'createdDate',
  }
}

const reservationsRequestsSlice = createSlice({
  name: 'reservationsRequests',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch reservations requests
      .addCase(ReservationsRequestsAsync.fetchReservationsRequests.pending, (state, action) => {
        state.reservationsRequests = null;
        state.total = 0;
        state.params = action.meta.arg;
      })
      .addCase(ReservationsRequestsAsync.fetchReservationsRequests.fulfilled, (state, action: any) => {
        state.reservationsRequests = action.payload.data;
        state.total = action.payload.total;
      })
      // create reservation request
      .addCase(ReservationsRequestsAsync.createReservationRequest.fulfilled, (state, action) => {
        state.reservationsRequests = state.reservationsRequests ? [action.payload, ...state.reservationsRequests] : [action.payload];
        state.total = state.total + 1;
      })
      // update reservation request
      .addCase(ReservationsRequestsAsync.updateReservationRequest.fulfilled, (state, action) => {
        state.reservationsRequests = state.reservationsRequests
          ? state.reservationsRequests.map(reservationsRequest => reservationsRequest._id === action.payload._id ? action.payload : reservationsRequest )
          : null;
      })
      // delete reservation request
      .addCase(ReservationsRequestsAsync.deleteReservationRequest.fulfilled, (state, action) => {
        const { arg:reservationRequestId } = action.meta;
        state.reservationsRequests = state.reservationsRequests
          ? state.reservationsRequests.filter(reservationsRequest => reservationsRequest._id !== reservationRequestId)
          : null;
        state.total = state.total - 1;
      })
      // mark as completed
      .addCase(ReservationsRequestsAsync.markAsSubmitted.fulfilled, (state, action) => {
        state.reservationsRequests = state.reservationsRequests
          ? state.reservationsRequests.map(reservationsRequest => reservationsRequest._id === action.payload._id ? action.payload : reservationsRequest)
          : state.reservationsRequests;
      })
      // reject
      .addCase(ReservationsRequestsAsync.reject.fulfilled, (state, action) => {
        state.reservationsRequests = state.reservationsRequests
          ? state.reservationsRequests.map(reservationsRequest => reservationsRequest._id === action.payload._id ? action.payload : reservationsRequest)
          : state.reservationsRequests;
      })
  }
})

export const reservationsRequestsActions = reservationsRequestsSlice.actions;
export default reservationsRequestsSlice.reducer;
