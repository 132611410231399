import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ICompetition from "models/Competition";
import CompetitionsAsync from "./competitionsAsync";

interface IState {
  competitions: ICompetition[] | null;
  total: number;
  params: any;
}

const initialState: IState = {
  competitions: null,
  total: 0,
  params: {
    limit: 20,
    page: 1,
    sortBy: 'createdDate',
  }
}

const competitionsSlice = createSlice({
  name: 'competitions',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch competitions
      .addCase(CompetitionsAsync.fetchCompetitions.pending, (state, action) => {
        state.competitions = null;
        state.total = 0;
        state.params = action.meta.arg;
      })
      .addCase(CompetitionsAsync.fetchCompetitions.fulfilled, (state, action: any) => {
        state.competitions = action.payload.competitions;
        state.total = action.payload.total;
      })
      // create competition
      .addCase(CompetitionsAsync.createCompetition.fulfilled, (state, action) => {
        state.competitions = state.competitions ? [action.payload, ...state.competitions] : [action.payload];
        state.total = state.total + 1;
      })
      // update competition
      .addCase(CompetitionsAsync.updateCompetition.fulfilled, (state, action) => {
        state.competitions = state.competitions ? state.competitions.map(competition => competition._id === action.payload._id ? action.payload : competition ) : null;
      })
      // delete competition
      .addCase(CompetitionsAsync.deleteCompetition.fulfilled, (state, action) => {
        const { arg:competitionId } = action.meta;
        state.competitions = state.competitions ? state.competitions.filter(competition => competition._id !== competitionId) : null;
        state.total = state.total - 1;
      })
  }
})

export const competitionsActions = competitionsSlice.actions;
export default competitionsSlice.reducer;