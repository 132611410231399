import { createAsyncThunk } from "@reduxjs/toolkit";
// Models
import IReservationRequest from "models/ReservationRequest";
// Actions
import { appActions } from "store/app/appSlice";
// Utilities
import HttpClient from "utilities/HttpClient";

const url = '/reservationsRequests';
const http:HttpClient = new HttpClient();

const ReservationsRequestsAsync = {
  fetchReservationsRequests: createAsyncThunk('reservationsRequests/fetchReservationsRequests', async (params:any) => {
    const { parseBody } = await http.get(url, params);
    return parseBody;
  }),
  createReservationRequest: createAsyncThunk('reservationsRequests/createReservationRequest', async (data:any, thunkApi) => {
    const { parseBody } = await http.post(url, data);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Запит на бронювання успішно створено' }));
    return parseBody as IReservationRequest;
  }),
  updateReservationRequest: createAsyncThunk('reservationsRequests/updateReservationRequest', async (data:any, thunkApi) => {
    const { reservationRequestId, reservationRequestData } = data;
    const { parseBody } = await http.put(`${url}/${reservationRequestId}`, reservationRequestData);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Запит на бронювання успішно оновлено' }));
    return parseBody as IReservationRequest;
  }),
  deleteReservationRequest: createAsyncThunk('reservationsRequests/deleteReservationRequest', async (reservationRequestId:string, thunkApi) => {
    await http.delete(`${url}/${reservationRequestId}`);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Запит на бронювання було видалено' }));
  }),
  markAsSubmitted: createAsyncThunk('reservationsRequests/markAsSubmitted', async (reservationRequestId:string, thunkApi) => {
    const { parseBody } = await http.post(`${url}/${reservationRequestId}/actions/markAsSubmitted`);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Запит на бронювання підтверджено' }));
    return parseBody as IReservationRequest;
  }),
  reject: createAsyncThunk('reservationsRequests/reject', async (reservationRequestId:string, thunkApi) => {
    const { parseBody } = await http.post(`${url}/${reservationRequestId}/actions/reject`);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Запит на бронювання відхилено' }));
    return parseBody as IReservationRequest;
  }),
}

export default ReservationsRequestsAsync;
