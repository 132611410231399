import React, { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import CompetitionsAsync from 'store/competitions/competitionsAsync';
// Models
import ICompetition from 'models/Competition';
// Components
import Title from 'components/Title';
// MUI
import { LoadingButton } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers'
import {
  Button, DialogActions, DialogContent, DialogTitle,
  Grid, TextField
} from '@mui/material';
// utilites
import { isRequired } from 'utilities/Validation';

interface Props {
  onClose: () => void;
  competition?: ICompetition;
}

interface IForm {
  startDate: any;
  endDate: any;
  name: string;
}

const CompetitionsForm: React.FC<Props> = ({ onClose, competition }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, formState: {errors} } = useForm<IForm>({
    defaultValues: {
      startDate: competition?.startDate || null,
      endDate: competition?.endDate || null,
      name: competition?.name || '',
    }
  });

  const onSubmit = handleSubmit((data: IForm) => {
    const { startDate, endDate, ...nextData } = data;
    const start = dayjs(startDate).format('YYYY-MM-DD')
    const end = dayjs(endDate).format('YYYY-MM-DD')

    const newData = {
      ...nextData,
      startDate: start,
      endDate: end,
    }
    setIsLoading(true);

    if (competition) {
      dispatch(CompetitionsAsync.updateCompetition({competitionId: competition._id, competitionData: newData}))
      .unwrap()
      .then(() => onClose())
      .finally(() => setIsLoading(false))
    } else {
      dispatch(CompetitionsAsync.createCompetition(newData))
      .unwrap()
      .then(() => onClose())
      .finally(() => setIsLoading(false))
    }
  });

  return (
    <Fragment>
      <DialogTitle>
        <Title>{`${competition ? 'Редагувати' : 'Створити'} змагання`}</Title>
      </DialogTitle>

      <DialogContent dividers>
        <form noValidate>
          <Grid container spacing={2} sx={{ pt: 4, pb: 4 }}>
            {/* name */}
            <Grid item xs={12}>
              <Controller
                control={control} name="name"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Назва"
                    fullWidth
                    required
                    error={!!errors?.name}
                    helperText={errors?.name ? errors.name.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Start date */}
              <Controller
                control={control} name="startDate"
                rules={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                  <MobileDatePicker
                    value={value || null}
                    onChange={onChange}
                    label="Дата початку"
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={Boolean(errors.startDate)}
                        helperText={errors.startDate ? errors.startDate.message : ''}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* End date */}
              <Controller
                control={control} name="endDate"
                rules={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                  <MobileDatePicker
                    value={value || null}
                    onChange={onChange}
                    label="Дата закінчення"
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={Boolean(errors.endDate)}
                        helperText={errors.endDate ? errors.endDate.message : ''}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Скасувати
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
        >
          {competition ? 'Зберегти' : 'Створити'}
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
};

export default CompetitionsForm;
