import { createAsyncThunk } from "@reduxjs/toolkit";
// Async
import AssetsAsync from 'store/assets/assetsAsync';
// Actions
import { appActions } from "store/app/appSlice";
// Models
import ISponsor from "models/Sponsor";
// Utilities
import HttpClient from "utilities/HttpClient";

const url = '/sponsors';
const http:HttpClient = new HttpClient();

const SponsorsAsync = {
  fetchSponsors: createAsyncThunk('sponsors/fetchSponsors', async () => {
    const { parseBody } = await http.get(url);
    return parseBody as ISponsor[];
  }),
  createSponsor: createAsyncThunk('sponsors/createSponsor', async (sponsor:any , thunkApi: any) => {
    const nextData = { ...sponsor };

    const { payload: images } : any = await thunkApi.dispatch(AssetsAsync.validateAssets({}));

    if (images.length) {
      nextData['logoId'] = images[0]._id;
    }

    const { parseBody } = await http.post(url, nextData);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Спонсора успішно додано' }));
    return parseBody as ISponsor;
  }),
  deleteSponsor: createAsyncThunk('sponsors/deleteSponsor', async (sponsorId:string, thunkApi) => {
    await http.delete(`${url}/${sponsorId}`);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Спонсора було видалено' }));
  }),
  updateSponsor: createAsyncThunk('sponsors/updateSponsor', async (data:any , thunkApi: any) => {
    const { sponsor, sponsorId } = data;
    const nextData = { ...sponsor };

    const { payload: images } : any = await thunkApi.dispatch(AssetsAsync.validateAssets({}));

    if (images.length) {
      nextData['logoId'] = images[0]._id;
    }
    const { parseBody } = await http.put(`${url}/${sponsorId}`, nextData);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Спонсора успішно оновлено' }));
    return parseBody as ISponsor;
  }),
  reorderSponsors: createAsyncThunk('sponsors/reorderSponsors', async (sponsorIds:string[] , thunkApi: any) => {
    const { parseBody } = await http.post(`${url}/reorder`, sponsorIds);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Спонсори успішно посортовані' }));
    return parseBody as ISponsor[];
  }),
}

export default SponsorsAsync;
