import React from 'react';
// MUI
import { Typography, Box } from '@mui/material';

const TemplatesHeader:React.FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h5">Шаблони</Typography>
    </Box>
  );
};

export default TemplatesHeader;
