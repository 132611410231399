import IOption from "models/Option";
import ReservationRequestStatuses from "types/ReservationRequestStatuses";

export const getOptionsFromEnum = (Enum: any) => {
  return (Object.keys(Enum) as Array<keyof typeof Enum>).map(key => ({value: Enum[key], label: key })) as IOption[];
}

export const statusTranslate = (status: ReservationRequestStatuses) => {
  switch (status) {
    case ReservationRequestStatuses.Pending:
      return 'Очікується'
    case ReservationRequestStatuses.Submitted:
      return 'Підтверджено'
    case ReservationRequestStatuses.Rejected:
      return 'Відхилено'
    case ReservationRequestStatuses.Cancelled:
      return 'Скасовано'
    default:
      return ''
  }
}
