import { appActions } from "store/app/appSlice";

const errorMiddleware = ({ dispatch }: any) => (next: any) => (action: any) => {
  const { type, payload, error } = action;

  if (type.endsWith('/rejected')) {
    dispatch(appActions.enqueueSnackbar({
      message: payload?.message || error?.message || 'Server error',
      options: { variant: 'error'},
    }))
  }

  return next(action);
}

export default errorMiddleware;
