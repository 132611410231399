import React, { useEffect, useState } from 'react';
// Types
import UserRoles from 'types/UserRoles';
// Components
import UsersHeader from './UsersHeader';
import UsersList from './UsersList';
// MUI
import { LinearProgress } from '@mui/material';

const UsersPage:React.FC<{roles: UserRoles[]}> = ({ roles }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);

    return () => {
      setLoaded(false);
    }
  }, []);

  if (!loaded) return <LinearProgress />;
  return (
    <React.Fragment>
      <UsersHeader roles={roles} />
      <UsersList roles={roles} />
    </React.Fragment>
  )
}

export default UsersPage;
