import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
// Hooks
import useDialog from 'hooks/useDialog';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useMenu from 'hooks/useMenu';
import useToggle from 'hooks/useToggle';
// Async
import UsersAsync from 'store/users/usersAsync';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
// Models
import IUser from 'models/User';
// Types
import UserRoles from 'types/UserRoles';
// MUI
import { makeStyles } from '@mui/styles';
import { Box, Card, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import UsersForm from './UsersForm';
import UserInfo from 'components/UserInfo';
// Utilities
import { formatPhone, textToCapitalizeCase } from 'utilities/StringFromatter';

type Props = {
  user: IUser,
};

const UserItem:FC<Props> = ({ user }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const currrentUser = useSelector(selectCurrentUser);


  const handleRemovePropertyOwnership = () => {
    dispatch(UsersAsync.deleteUser(user._id));
  }

  const { open:openRemoveModal, toggle:toggleRemoveModal } = useToggle();
  const { Menu, MenuItem, openMenu } = useMenu();
  const { Dialog, openDialog, closeDialog } = useDialog();
  const { Dialog:UserInfoDialog, openDialog:openUserInfoDialog, closeDialog:closeUserInfoDialog } = useDialog();

  return (
    <Fragment>
      <ConfirmDeleteModal open={openRemoveModal} onClose={toggleRemoveModal} handleRemove={handleRemovePropertyOwnership} title={`${user.firstName} ${user.lastName}`} />

      <Dialog>
        <UsersForm onClose={closeDialog} user={user} />
      </Dialog>

      <UserInfoDialog>
        <UserInfo user={user} onClose={closeUserInfoDialog} />
      </UserInfoDialog>

      <Card sx={{ p: 2, borderRadius: 0, backgroundColor: '#fff', borderBottom: '1px solid #eee', width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} md={4} alignSelf="center" sx={{ order: -1 }}>
            <Typography className={classes.title}>Ім'я:</Typography>
            <Typography className={classes.text}>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} alignSelf="center">
            <Typography className={classes.title}>E-mail:</Typography>
            <Tooltip title={user.email}>
              <Typography className={classes.text} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {user.email}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={3} alignSelf="center">
            <Typography className={classes.title}>Телефон:</Typography>
            <Typography className={classes.text}>
              {user.phone ? formatPhone(user.phone) : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={1} alignSelf="center">
            <Typography className={classes.title}>Роль:</Typography>
            <Typography className={classes.text}>
              {textToCapitalizeCase(user.role)}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} alignSelf="center" sx={{ order: { xs: -1, md: 0 } }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Open context menu">
                <IconButton aria-label="open context menu" onClick={openMenu}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu>
                {user.role === UserRoles.Client && (
                  <MenuItem onClick={openUserInfoDialog}>Детальніше</MenuItem>
                )}
                <MenuItem onClick={openDialog}>Редагувати</MenuItem>
                {currrentUser?._id !== user._id && (
                  <MenuItem onClick={toggleRemoveModal} sx={{ color: '#D32F2F' }}>Видалити</MenuItem>
                )}
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
}

export default UserItem;

const useStyles = makeStyles({
  title: {
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
