import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import PricesAsync from 'store/prices/pricesAsync';
// Selectors
import { selectPrices } from 'store/prices/pricesSelectors';
// Compoentns
import PriceItem from './PriceItem';
// Mui
import { Box } from '@mui/material';

const PricesList:React.FC = () => {
  const dispatch = useAppDispatch();
  const prices = useSelector(selectPrices);

  useEffect(() => {
    dispatch(PricesAsync.fetchPrices());
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ mt: 4, display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
      {prices.map(price => (
        <PriceItem key={price._id} price={price} />
      ))}
    </Box>
  );
}

export default PricesList;
