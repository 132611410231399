import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import TemplatesAsync from "./templatesAsync";
// Models
import ITemplate from "models/Template";

interface IState {
  templates: ITemplate[] | null;
}

const initialState: IState = {
  templates: null,
}

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch templates
      .addCase(TemplatesAsync.fetchTemplates.fulfilled, (state, action) => {
        state.templates = action.payload;
      })
      // update template
      .addCase(TemplatesAsync.updateTemplate.fulfilled, (state, action) => {
        state.templates = state.templates
          ? state.templates.map(template => template._id === action.payload._id ? action.payload : template )
          : null;
      })
  }
})

export const templatesActions = templatesSlice.actions;
export default templatesSlice.reducer;
