import { FC, Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import ReservationsRequestsAsync from 'store/reservationsRequests/reservationsRequestsAsync';
// Models
import IReservationRequest from 'models/ReservationRequest';
// Types
import ReservationRequestStatuses from 'types/ReservationRequestStatuses';
// Components
import Phone from 'components/Phone';
import Title from 'components/Title';
import { Select } from 'components/Controls';
// MUI
import { LoadingButton } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers'
import {
  Box, Button, Chip, DialogActions, DialogContent, DialogTitle,
  Grid, MenuItem, TextField, Typography
} from '@mui/material';
// Utilities
import { isRequired } from 'utilities/Validation';
import { getOptionsFromEnum, statusTranslate } from 'utilities/Utilities';
// dayjs
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);

interface Props {
  onClose: () => void;
  reservationRequest?: IReservationRequest;
}

interface IForm {
  startDate: any;
  endDate: any;
  firstName: string;
  lastName: string;
  phone: string;
  carNumber: string;
  sectors: string[];
  notes?: string;
  status: ReservationRequestStatuses;
}

const sectors:string[] = new Array(21).fill('').map((_, index) => `${index}`);

const ReservationsRequestsForm:FC<Props> = ({ onClose, reservationRequest }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, formState: {errors}, watch, setValue } = useForm<IForm>({
    defaultValues: {
      startDate: reservationRequest?.startDate || null,
      endDate: reservationRequest?.endDate || null,
      firstName: reservationRequest?.firstName || '',
      lastName: reservationRequest?.lastName || '',
      phone: reservationRequest?.phone || '',
      carNumber: reservationRequest?.carNumber || '',
      sectors: reservationRequest?.sectors || [],
      notes: reservationRequest?.notes || '',
      status: reservationRequest?.status || ReservationRequestStatuses.Pending,
    }
  });

  const startDateWatcher = watch('startDate');
  const endDateWatcher = watch('endDate');

  const onSubmit = handleSubmit((data: IForm) => {
    const { startDate, endDate, ...nextData } = data;
    const start = dayjs(startDate).format('YYYY-MM-DD')
    const end = dayjs(endDate).format('YYYY-MM-DD')

    const newData: any = {
      ...nextData,
      startDate: start,
      endDate: end,
    }
    setIsLoading(true);

    if (reservationRequest) {
      dispatch(ReservationsRequestsAsync.updateReservationRequest({reservationRequestId: reservationRequest._id, reservationRequestData: newData}))
        .unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false))
    } else {
      dispatch(ReservationsRequestsAsync.createReservationRequest(newData))
        .unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false))
    }
  });

  return (
    <Fragment>
      <DialogTitle>
        <Title>{`${reservationRequest ? 'Редагувати' : 'Створити'} запит на бронювання`}</Title>
      </DialogTitle>

      <DialogContent dividers>
        <form noValidate>
          <Grid container spacing={2} sx={{ pt: 4, pb: 4 }}>
            <Grid item xs={12}>
              <Controller
                control={control} name="status"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Статус"
                    options={statusOptions}
                    required
                    error={Boolean(errors.status)}
                    helperText={errors.status ? errors.status.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Start date */}
              <Controller
                control={control} name="startDate"
                rules={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                  <MobileDatePicker
                    value={value || null}
                    onChange={(date: any) => {
                      onChange(date);
                      if ((endDateWatcher && dayjs(endDateWatcher).isSameOrBefore(date)) || !endDateWatcher) {
                        setValue('endDate', dayjs(date).add(1, 'day'));
                      }
                    }}
                    minDate={dayjs()}
                    label="Дата заїзду"
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={Boolean(errors.startDate)}
                        helperText={errors.startDate ? errors.startDate.message : ''}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* End date */}
              <Controller
                control={control} name="endDate"
                rules={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                  <MobileDatePicker
                    value={value || null}
                    onChange={(date: any) => {
                      onChange(date);
                      if (!startDateWatcher) {
                        setValue('startDate', dayjs(date).subtract(1, 'day'));
                      }
                    }}
                    minDate={startDateWatcher ? dayjs(startDateWatcher).add(1, 'day') : dayjs().add(1, 'day')}
                    label="Дата виїзду"
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={Boolean(errors.endDate)}
                        helperText={errors.endDate ? errors.endDate.message : ''}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            {/* fistName */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="firstName"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Ім'я"
                    fullWidth
                    required
                    error={!!errors?.firstName}
                    helperText={errors?.firstName ? errors.firstName.message : null}
                  />
                )}
              />
            </Grid>
            {/* lastName */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="lastName"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Прізвище"
                    fullWidth
                    required
                    error={!!errors?.lastName}
                    helperText={errors?.lastName ? errors.lastName.message : null}
                  />
                )}
              />
            </Grid>
            {/* carNumber */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="carNumber"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Номер авто"
                    fullWidth
                  />
                )}
              />
            </Grid>
            {/* sector */}
            <Grid item xs={12} md={6}>
              <Controller
                control={control} name="sectors"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    select
                    label="Сектор(и)"
                    required
                    error={!!errors?.sectors}
                    helperText={errors?.sectors ? errors.sectors.message : null}
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected: any) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, maxWidth: '100%', overflow: 'hidden' }}>
                          {selected.slice(0, 3).map((value:string) => (
                            <Chip key={value} label={value} sx={{ height: '23px' }} />
                          ))}
                          {selected.length > 3 && (
                            <Typography sx={{ pl: 1 }}>{`+${selected.length - 3}`}</Typography>
                          )}
                        </Box>
                      )
                    }}
                  >
                    {sectors.map(sector => (
                      <MenuItem key={sector} value={`${sector}`}>{sector}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {/* Phone */}
            <Grid item xs={12}>
              <Controller
                control={control} name="phone"
                rules={{ required: isRequired }}
                render={({ field:{ value, onChange } }) => (
                  <Phone
                    margin="none"
                    label="Телефон" value={value} onChange={onChange}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone ? (errors.phone as any).message : ''}
                    required={true}
                  />
                )}
              />
            </Grid>
            {/* notes */}
            <Grid item xs={12}>
              <Controller
                control={control} name="notes"
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    label="Повідомлення"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Скасувати
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
        >
          {reservationRequest ? 'Зберегти' : 'Створити'}
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
};

export default ReservationsRequestsForm;

const statusOptions = getOptionsFromEnum(ReservationRequestStatuses).map(status => ({ ...status, label: statusTranslate(status.value as ReservationRequestStatuses) }));
