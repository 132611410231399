import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Pages
import ProfilePage from '../AdminPage/ProfilePage';
import ReservationsRequests from '../AdminPage/ReservationsRequests';

const ManagerRouting:React.FC = () => {
  return (
    <Routes>
      <Route path="reservationsRequests" element={<ReservationsRequests />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="*" element={<Navigate to="/manager/reservationsRequests" />} />
    </Routes>
  )
}

export default ManagerRouting;
