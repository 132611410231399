import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import CompetitionsAsync from 'store/competitions/competitionsAsync';
// Selectors
import { selectCompetitions, selectParams, selectTotal } from "store/competitions/competitionsSelectors";
// Models
import ICompetition from 'models/Competition';
// Components
import ReservationItem from "./CompetitionItem";
import NoData from 'components/NoData';
// MUI
import { Box, LinearProgress, TablePagination } from "@mui/material";
import { makeStyles } from '@mui/styles';

const CompetitionsList:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const competitions = useSelector(selectCompetitions);
  const total = useSelector(selectTotal);
  const params = useSelector(selectParams);

  const handleChangePage = (_:any, page: number) => {
    dispatch(CompetitionsAsync.fetchCompetitions({ ...params, page: page + 1 }));
  }

  const handleChangeLimit = (e:any) => {
    const { value: limit } = e.target;
    dispatch(CompetitionsAsync.fetchCompetitions({ ...params, page: 1, limit, }));
  }

  useEffect(() => {
    dispatch(CompetitionsAsync.fetchCompetitions(params));
    // eslint-disable-next-line
  }, []);

  if (!competitions) return <LinearProgress sx={{ mt: 2 }} />;
  if (competitions && !competitions.length) return <NoData />;

  return (
    <Box className={classes.list} sx={{ mt: 3 }}>
      <Box className={classes.items}>
        {competitions?.map((competition: ICompetition) => (
          <ReservationItem key={competition._id} competition={competition} />
        ))}
      </Box>
      <Box>
        <TablePagination
          className={classes.pagination}
          component="div"
          count={total}
          page={params.page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={params.limit}
          onRowsPerPageChange={handleChangeLimit}
          labelRowsPerPage="Показувати"
          labelDisplayedRows={({from, to, count}) => `${from}–${to} з ${count}`}
          rowsPerPageOptions={[20, 50, 100]}
        />
      </Box>
    </Box>
  );
}

export default CompetitionsList;

const useStyles = makeStyles({
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 208px)',
    },
  },
  pagination: {
    boxShadow: '0px -3px 6px -1px rgb(0 0 0 / 8%)',
  },
});
