import { createAsyncThunk } from "@reduxjs/toolkit";
// Models
import IUser from "models/User";
// Actions
import { appActions } from "store/app/appSlice";
import { authActions } from "store/auth/authSlice";
// Utilities
import HttpClient from "utilities/HttpClient";

const url = '/users';
const http:HttpClient = new HttpClient();

const UsersAsync = {
  fetchMe: createAsyncThunk('users/fetchMe', async (_, thunkApi) => {
    const { parseBody } = await http.get(`${url}/fetchMe`);
    const user: IUser = parseBody as IUser;
    thunkApi.dispatch(authActions.setAuthenticated(true));
    return user;
  }),
  fetchUsers: createAsyncThunk('users/fetchUsers', async (params: any, thunkApi) => {
    const { parseBody } = await http.get(url, params);
    return parseBody;
  }),
  createUser: createAsyncThunk('users/createUser', async (data: any, thunkApi) => {
    const { parseBody } = await http.post(url, data);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Користовача успішно створено' }));
    const user: IUser = parseBody as IUser;
    return user;
  }),
  updateUser: createAsyncThunk('users/updateUser', async (data: any, thunkApi) => {
    const { userId, userData } = data;
    const { parseBody } = await http.put(`${url}/${userId}`, userData);
    const user: IUser = parseBody as IUser;
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Користовача успішно оновлено' }));
    return user;
  }),
  deleteUser: createAsyncThunk('users/deleteUser', async (userId: string, thunkApi) => {
    await http.delete(`${url}/${userId}`);
    thunkApi.dispatch(appActions.enqueueSnackbar({ message: 'Користовач був видалений' }));
  })
}

export default UsersAsync;
