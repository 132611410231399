import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch } from 'hooks/useAppDispatch';
import dayjs from 'dayjs';
// Models
import ITemplate from 'models/Template';
// Async
import TemplatesAsync from 'store/templates/templatesAsync';
// Mui
import { LoadingButton } from '@mui/lab';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Button, Grid, TextField
} from '@mui/material';
// Utilities
import { isRequired } from 'utilities/Validation';
// Components
import CustomEditor from 'components/CustomEditor';

interface IFormData {
  title: string;
  slug: string;
  body: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  template: ITemplate;
};

const TemplatesForm:React.FC<Props> = ({ open, onClose, template }) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { control, handleSubmit, formState:{ errors }, reset } = useForm<IFormData>({
    defaultValues: {
      title: template.title,
      slug: template.slug,
      body: template.body,
    }
  });

  useEffect(() => {
    return () => {
      reset();
    }
    // eslint-disable-next-line
  }, []);

  const handleClose = (_:any, reason: "backdropClick" | "escapeKeyDown") => {
    if ( reason === 'backdropClick' ) return;
    onClose();
  }

  const onSubmit = handleSubmit((data:IFormData) => {
    setIsLoading(true);

    dispatch(TemplatesAsync.updateTemplate({ templateId: template._id, templateData: data }))
      .unwrap()
      .then(() => onClose())
      .finally(() => setIsLoading(false))
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      disableEnforceFocus
    >
      <DialogTitle>
        Редагувати шаблон
        <br />
        <span style={{ fontSize: '12px', color: 'gray' }}>
          {`Останнє оновлення: ${template.updatedBy}, ${dayjs(template.updatedAt).format('DD.MM.YY')}р.`}
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control} name="title"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Назва"
                    error={Boolean(errors.title)}
                    helperText={errors.title ? errors.title.message : ''}
                    required
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control} name="body"
                rules={{ required: isRequired }}
                render={({ field: {value, onChange} }) => (
                  <CustomEditor
                    value={value}
                    onChange={(_, editor) => {
                      const value = editor.getData();
                      onChange(value);
                    }}
                    required={true}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={onClose}
        >
          Скасувати
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          Зберегти
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default TemplatesForm;
