import React, { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import SponsorsAsync from 'store/sponsors/sponsorsAsync';
// Models
import ISponsor from 'models/Sponsor';
// Components
import Title from 'components/Title';
import Uploader from 'components/Uploader';
// MUI
import { LoadingButton } from '@mui/lab';
import {
  Button, DialogActions, DialogContent, DialogTitle,
  Grid, TextField
} from '@mui/material';
// Utilities
import { isRequired } from 'utilities/Validation';

type Props = {
  onClose: () => void,
  sponsor?: ISponsor,
}

interface IForm {
  name: string;
  url: string;
  position: number;
}

const SponsorsForm:React.FC<Props> = ({ sponsor, onClose }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm<IForm>({
    defaultValues: {
      name: sponsor?.name || '',
      url: sponsor?.url || '',
      position: sponsor?.position || 1,
    }
  })

  const onSubmit = handleSubmit((data: IForm) => {
    setIsLoading(true);

    if (sponsor) {
      dispatch(SponsorsAsync.updateSponsor({ sponsor: data, sponsorId: sponsor._id }))
        .unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false))
    } else {
      dispatch(SponsorsAsync.createSponsor(data))
        .unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false))
    }
  })

  return (
    <Fragment>
      <DialogTitle>
        <Title>{`${sponsor ? 'Редагувати' : 'Створити'} спонсора`}</Title>
      </DialogTitle>

      <DialogContent dividers>
        <form noValidate>
          <Grid container spacing={2} sx={{ pt: 4, pb: 4 }}>
            {/* name */}
            <Grid item xs={12} sm={8}>
              <Controller
                control={control} name="name"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Назва"
                    fullWidth
                    required
                    error={!!errors?.name}
                    helperText={errors?.name ? errors.name.message : null}
                  />
                )}
              />
            </Grid>
            {/* url */}
            <Grid item xs={12}>
              <Controller
                control={control} name="url"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Url компанії"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Uploader assets={sponsor?.logo ? [sponsor.logo] : undefined} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Скасувати
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
        >
          {sponsor ? 'Зберегти' : 'Створити'}
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
}

export default SponsorsForm;
