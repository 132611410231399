import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Async
import AuthAsync from 'store/auth/authAsync';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
// MUI
import { Box, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  PersonOutline as PersonOutlineIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
} from '@mui/icons-material';

const UserMenu:React.FC = () => {
  const dispatch = useAppDispatch();
  const menuRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu);
  }

  const currentUser = useSelector(selectCurrentUser);

  const handleSignOut = () => {
    dispatch(AuthAsync.signOut());
  }

  return (
    <React.Fragment>
      <Box onClick={handleToggleMenu} sx={{ display: 'flex', gap: 1, alignItems: 'center', cursor: 'pointer', color: '#fff' }}>
        <AccountCircleOutlinedIcon />
        <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>
          {currentUser?.firstName}
        </Typography>
        <Box ref={menuRef}>
          <ExpandMoreIcon sx={{ display: { xs: 'none', sm: 'inline-flex' } }} />
        </Box>
      </Box>

      <Menu
        anchorEl={menuRef.current}
        id="account-menu"
        open={openMenu}
        onClose={handleToggleMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: '160px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem onClick={handleToggleMenu} component={NavLink} to={`/${currentUser?.role}/profile`}>
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          Профіль
        </MenuItem>

        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <LogoutOutlinedIcon />
          </ListItemIcon>
          Вийти
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default UserMenu;
