import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IPrice from "models/Price";
import PricesAsync from "./pricesAsync";

interface IState {
  prices: IPrice[];
}

const initialState: IState = {
  prices: [],
}

const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch prices
      .addCase(PricesAsync.fetchPrices.fulfilled, (state, action) => {
        state.prices = action.payload;
      })
      // create price
      .addCase(PricesAsync.createPrice.fulfilled, (state, action) => {
        state.prices = [action.payload, ...state.prices];
      })
      // delete price
      .addCase(PricesAsync.deletePrice.fulfilled, (state, action) => {
        const { arg:priceId } = action.meta;
        state.prices = state.prices.filter(price => price._id !== priceId);
      })
      // update price
      .addCase(PricesAsync.updatePrice.fulfilled, (state, action) => {
        state.prices = state.prices.map(price => price._id === action.payload._id ? action.payload : price);
      })
  }
})

export const pricesActions = pricesSlice.actions;
export default pricesSlice.reducer;